// =============================================
// _var.scss
// =============================================


/* Colors
/* ============================================= */

// Primary
$c-1:           #ae223b;
$c-2:           #760049;
$c-3:           #333333;
$c-4:           #ebf3f9;


// Readable
$c-text:        #333;
$c-title:       #333;
$c-link:        $c-1;
$c-link-hover:  $c-2;
$c-border:      #c8cfd4;

$c-text-footer: #999;
$c-bkg-footer: #333;
$c-link-hover-footer: #b2b2b2;

$c-bkg-body: #e8e8e8;


// general
$white: #fff;
$black: #000;
$c-error: #ff3333;
$c-success: #009e55;




/* Fonts
/* ============================================= */
$f-text: 'Open Sans', sans-serif;
$f-title: 'Open Sans', serif;




/* type formation
/* ============================================= */
$typeFormation : (
    'formation-courte'                  : 'fi_clock',
    'accompagnement-pro'                : 'fi_pyramid',
    'formation-diplomante'              : 'fi_ribbon',
    'formation-internationale'          : 'fi_globe',
    'formation-intra'                   : 'fi_in-home',
    'formation-de-branches-et-reseaux'  : 'fi_network',
    'conference-journee-dactualites'    : 'fi_micro'
);




/* Social colors
/* ============================================= */
$c-facebook           : #365397;
$c-twitter            : #00a9f1;
$c-linkedin           : #006db3;
$c-apple              : #737373;
$c-google             : #4285f4;
$c-google-plus        : #e0452c;
$c-youtube            : #ff3333;
$c-vimeo              : #48b6ed;
$c-pinterest          : #ce1a19;
$c-yelp               : #c30f00;
$c-dribbble           : #ed4584;
$c-amazon             : #ff9700;
$c-ebay               : #083790;
$c-skype              : #00acf4;
$c-instagram          : #396d9a;
$c-dropbox            : #0d84de;
$c-flickr             : #ea0066;
$c-github             : #2f2f2f;
$c-basecamp           : #6bbd6d;
$c-tumblr             : #304c68;
$c-foursquare         : #207dc5;
$c-box                : #1277bc;
$c-rss                : #f26522;




/* Animation
/* ============================================= */
@keyframes roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
