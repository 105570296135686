@import "var";
@import "mixin";

@import "lib/jeet/jeet";
@import "lib/icon-font/font/template";
@import "lib/bootstrap-4.2.1/bootstrap";

@import "front/normalize";
@import "front/grid";
//@import "front/form";
@import "front/component";
@import "front/helper";
@import "front/wordpress";
@import "front/header";
@import "front/footer";


.form-check-label {
  margin-left: 1rem; // Override default `<label>` bottom margin
}

.form-check-input {
  margin-top: 1rem; // Override default `<label>` bottom margin
}

fieldset {
  padding: 0;
}

.form-group {
  margin-bottom: 1rem;
  margin-top: 2.5rem;
}

.content{
  padding-bottom: 5rem;
}