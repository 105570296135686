@mixin breakpoint($point) {

    @if $point == ultrawide {
        @media screen and (min-width: 1666px) { @content; }
    }

    @if $point == wide {
        @media screen and (min-width: 961px) and (max-width: 1666px) { @content; }
    }

    @if $point == mediumwide {
        @media screen and (min-width: 961px) and (max-width: 1280px) { @content; }
    }

    @if $point == smallwide {
        @media screen and (min-width: 961px) and (max-width: 1024px) { @content; }
    }

    @if $point == tablette {
        @media screen and (max-width: 960px) { @content; }
    }

    @else if $point == mobile {
        @media screen and (max-width: 640px)  { @content; }
    }
}

@mixin fa($icon) {
    font-family: Font Awesome\ 5 Free;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    font-weight: 900;
    line-height: 1;
    transform: translate(0, 0);
    content: #{'"\\' + $icon + '"'};
}

@mixin fab($icon) {
    font-family: Font Awesome\ 5 Brands;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    font-weight: 900;
    line-height: 1;
    transform: translate(0, 0);
    content: #{'"\\' + $icon + '"'};
}

@mixin far($icon) {
    font-family: Font Awesome\ 5 Free;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    font-weight: 400;
    line-height: 1;
    transform: translate(0, 0);
    content: #{'"\\' + $icon + '"'};
}

@mixin fas($icon) {
    font-family: Font Awesome\ 5 Free;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    font-weight: 900;
    line-height: 1;
    transform: translate(0, 0);
    content: #{'"\\' + $icon + '"'};
}

@mixin retinize($file, $type, $width, $height) {
    background-image: url('../images/' + $file + '.' + $type);

    @media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
        & {
            background-image: url('../images/' + $file + '@2x.' + $type);
            background-size: $width $height;
        }
    }
}

@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}
}

@mixin height-slide() {

    height: 690px;

    @include breakpoint(mediumwide) {
        height: 600px;
    }

    @include breakpoint(smallwide) {
        height: 550px;
    }

    @include breakpoint(tablette) {
        height: 500px;
    }

    @include breakpoint(mobile) {
        height: 350px;
    }
}
