// =============================================
// _footer.scss - Element display in footer.php
// =============================================




/*  Other site
/* ============================================= */
.accelerators-other-site{

    background-color: #e5edf3;
    padding: 40px 0;

    @include breakpoint(tablette) {

        padding: 40px 0 0;
    }

    @include breakpoint(mobile) {

        padding: 20px 0 0;
    }

    ul{

        @include clearfix;
    }

    &Item{

        @include j-column(1/4, 0, 4, 3);

        @include breakpoint(tablette) {

            @include j-column(1/2, 0, 2, 6);
            margin-bottom: 6%;
        }

        a, img{

            width: 100%;
        }
    }
}




/*  Footer
/* ============================================= */
.footer{

    position: relative;
    width: 100%;
    padding: 70px 0;
    background-color: $c-bkg-footer;
    color: $c-text-footer;
    font-size: 1.4rem;

    @include breakpoint(tablette) {

        padding: 35px 0;
    }

    &Contact{

        @include j-column(1/4, $gutter: 5);

        @include breakpoint(tablette) {

            @include j-span(1);
            margin-bottom: 40px;

            .touch & {

                @include j-column(1/2, $gutter: 5);

                @include breakpoint(mobile) {

                    @include j-span(1);
                    margin-bottom: 20px;
                }
            }

            @include breakpoint(mobile) {

                @include j-span(1);
                margin-bottom: 20px;
            }
        }

        &Logo{

            margin-bottom: 30px;

            @include breakpoint(mobile) {

                margin-bottom: 10px;
            }

            img{

                width: 115px;
            }
        }

        &Address{

            span{

                display: block;
            }
        }

        &Button{

            margin-top: 30px;

            @include breakpoint(mobile) {

                margin-top: 10px;
            }
        }
    }

    &Item{

        @include j-column(3/4, $gutter: 5);

        @include breakpoint(tablette) {

            @include j-span(1);
        }

        @include breakpoint(mobile) {

            @include j-span(1);
        }
    }
}




/*  Newsletters
/* ============================================= */
.newsletter{

    display: flex;
    line-height: 50px;

    @include breakpoint(tablette) {

        .touch & {

            display: block;
            @include j-column(1/2, $gutter: 5);

            @include breakpoint(mobile) {

                @include j-span(1);
            }
        }
    }

    @include breakpoint(mobile) {

        display: block;
        @include j-span(1);
    }

    &Title{

        color: $white;
        text-transform: uppercase;
        font-weight: 600;
        margin-right: 20px;
    }

    &Form{

        display: flex;
        height: 50px;
        flex: 1;

        @include breakpoint(tablette) {

            .touch & {

                display: block;
            }
        }

        @include clearfix;

        .formSelect{

            margin-right: 20px;
            flex: 1;

            @include breakpoint(tablette) {

                .touch & {

                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }

            &:before{

                top: 50%;
                right: 20px;
                color: $white;
            }
        }

        select{

            background-color: #5c5c5c;
            color: $white;
            border: 0;
            text-indent: 20px;
            padding-right: 40px;
            font-weight: 600;
            font-size: 1.4rem;
        }

        &Info{

            flex: 1;
        }

        &Input{

            float: left;
            width: calc( 100% - 50px );
        }

        .button{

            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            padding: 0;
        }
    }
}



/*  Sitemap
/* ============================================= */
.sitemap{

    margin-top: 40px;

    @include breakpoint(mobile) {

        margin-top: 20px;
    }

    &Item{

        @include j-column(1/3, 0, 3, 4);

        @include breakpoint(mobile) {

            @include j-span(1);
        }

        &Title{
            margin-bottom: 20px;
            a{

                text-transform: uppercase;
                color: $white;
                font-weight: 600;
                line-height: 20px;
                display: block;

                &:hover{

                    color: $c-link-hover-footer;
                }
            }
        }

        &Social{

            margin-top: 60px;

            @include breakpoint(mobile) {

                margin-top: 20px;
            }

            .socialItem{

                margin-right: 15px;
            }

            a{

                color: $white;

                &:hover{

                    color: $c-link-hover-footer;
                }
            }
        }

        &--other-site{

            a{

                text-transform: none;
            }
        }
    }
}




/*  Accroche
/* ============================================= */
.footerAccroche{

    margin-top: 70px;

    @include breakpoint(mobile) {

        margin-top: 20px;
    }
}




/*  Copyright
/* ============================================= */
.copyright{

    position: relative;
    z-index: 1;
    text-align: center;
    margin-top: 60px;

    @include breakpoint(mobile) {

        text-align: left;
        margin-top: 20px;
        padding: 0 20px 0;
    }

    a{

        color: #999;

        &:hover{

            color: $white;
        }

        &.light{

            color: $white;
        }
    }

    i{

        color: $white;
    }
}
