// =============================================
// _grid.scss - Grid / layout
// =============================================

/* General
/* ============================================= */




.wrapper{

    position: relative;
    max-width: 2000px;
    margin: 0 auto;
    background-color: $white;
    overflow: hidden;
}




.container {

    max-width: 1260px;
    margin: 0 auto;
    padding: 0 20px;

    @include breakpoint(mediumwide) {

        padding: 0 40px;
    }

    @include breakpoint(tablette) {

        padding: 0 40px;
    }

    @include breakpoint(mobile) {

        padding: 0 20px;
    }

    &:after{

        content: '';
        display: block;
        clear: both;
    }

    .content{

        @include breakpoint(tablette) {

            padding:{
                left: 0;
                right: 0;
            }
        }
    }
}




.hentry{

    line-height: 2;

    > *:first-child{

        margin-top: 0;
    }

    > *:last-child{

        margin-bottom: 0;
    }

    &Parts{

        &Item{

            padding: 75px 0;

            @include breakpoint(tablette) {

                padding: 40px 0;
            }

            @include breakpoint(mobile) {

                padding: 15px 0;
            }

            &:first-child{

                margin-top: 75px;

                @include breakpoint(tablette) {

                    margin-top: 40px;
                }

                @include breakpoint(mobile) {

                    margin-top: 15px;
                }
            }

            &Content{

                @include breakpoint(mobile) {

                    padding: 0;
                }

                &:last-child {

                    padding-bottom: 0;
                }

                > *:first-child{

                    margin-top: 0;
                }

                > *:last-child {

                    margin-bottom: 0;
                }
            }
        }
    }
}




.row{

    &:after{

        content: '';
        display: block;
        clear: both;
    }
}




.content{

    margin: 75px auto;
    max-width: 840px;
    line-height: 2;
    padding: 0 20px;

    @include breakpoint(tablette) {

        padding: 0 40px;
        margin: 50px auto;
    }

    @include breakpoint(mobile) {

        padding: 0 20px;
        margin: 35px auto;
    }

    .content{

        padding: 0;
    }
}




.content-with-sidebar{

    max-width: 1260px;
    margin: 75px auto;
    padding: 0 20px;

    @include clearfix;

    @include breakpoint(mediumwide) {

        padding: 0 40px;
    }

    @include breakpoint(tablette) {

        padding: 0 40px;
    }

    @include breakpoint(mobile) {

        margin: 35px auto;
        padding: 0 20px;
    }

    .content{

        float: left;
        width: calc( 100% - 375px );
        margin: 0 100px 0 0;
        padding: 0;

        @include breakpoint(mediumwide) {

            width: calc( 100% - 335px );
            margin: 0 50px 0 0;
        }

        @include breakpoint(smallwide) {

            width: calc( 100% - 335px );
        }

        @include breakpoint(tablette) {

            width: 100%;
            margin: 0 auto;
            float: none;
        }

        .touch & {

            width: 100%;
            margin: 0 auto;
            float: none;
        }
    }

    .sidebar{

        width: 275px;
        float: left;
        margin: 0 0 80px;

        .touch & {

            width: 100%;
            margin: 75px auto;
            max-width: 840px;
            float: none;

            @include breakpoint(mobile) {

                margin: 35px auto;
            }
        }

        @include breakpoint(mobile) {

            margin: 35px auto;
        }
    }

    &--reverse{

        .content{

            margin: 0 0 0 100px;

            @include breakpoint(tablette) {

                margin: 80px auto;
            }
        }
    }
}




.entityList{

    margin: 75px auto calc( 75px - 3% );

    @include breakpoint(tablette) {

        margin: 50px auto calc( 50px - 6% );
    }

    @include breakpoint(mobile) {

        margin: 35px auto;
    }

    @include clearfix;

    > [class*="Item"] {

        @include j-column(1/3, 0, 3, 3);
        margin-bottom: 3%;

        @include breakpoint(tablette) {

            @include j-column(1/2, 0, 2, 6);
            margin-bottom: 6%;
        }

        @include breakpoint(mobile) {

            @include j-span(1);
            margin-bottom: 20px;

            &:last-child{

                margin-bottom: 0;
            }
        }
    }
}



.hentryPartsItem--offre-emploi-list{

    @include clearfix;

    .entityItem, .formationItem{

        @include j-column(1/2, 0, 2, 4);
        margin-bottom: 4%;

        @include breakpoint(tablette) {

            @include j-column(1/2, 0, 2, 6);
            margin-bottom: 6%;
        }

        @include breakpoint(mobile) {

            @include j-span(1);
            margin-bottom: 20px;
        }
    }
}




.page-contact{

    @include clearfix;

    @include breakpoint(tablette) {

        display: flex;
        flex-direction: column-reverse;
    }

    .contactMap{

        float: left;
        width: 50%;

        @include breakpoint(smallwide) {

            width: 60%;
        }

        @include breakpoint(tablette) {

            width: 100%;
        }
    }

    .contactContent{

        float: left;
        width: 50%;

        @include breakpoint(smallwide) {

            width: 40%;
        }

        @include breakpoint(tablette) {

            width: 100%;
        }
    }

    .content{

        max-width: 480px;
        width: calc( 100% - 40px );
        margin-left: 20px;

        @include breakpoint(tablette) {

            max-width: 640px;
            margin: {
                left: auto;
                right: auto;
            }
        }
    }
}




.actualitesList{

    margin: 75px auto calc( 75px - 4% );

    @include breakpoint(tablette) {

        margin-bottom: calc( 75px - 6% );
    }

    @include breakpoint(mobile) {

        margin: 35px auto;
    }

    @include clearfix;

    > [class*="Item"]{

        @include j-column(1/3, 0, 3, 4);
        margin-bottom: 4%;

        @include breakpoint(tablette) {

            @include j-column(1/2, 0, 2, 6);
            margin-bottom: 6%;
        }

        @include breakpoint(mobile) {

            @include j-span(1);
            margin-bottom: 20px;
        }

        .home & {

            @include j-column(1/3, 0, 3, 4);
            margin-bottom: 0;

            @include breakpoint(tablette) {

                margin: 0 !important;
            }
        }
    }
}

.actualites{

    &Action{

        @include breakpoint(mobile) {

            display: flex;
        }

        @include clearfix;

        &Item{

            margin-top: 40px;
            text-align: center;

            .touch & {

                margin-top: 0;
            }

            &--actualites{

                @include j-column(2/3, $gutter: 4);

                @include breakpoint(tablette) {

                    @include j-column(1/2, $gutter: 4);
                }

                @include breakpoint(mobile) {

                    float: none;
                    width: auto;

                    a{

                        width: 100%;
                    }
                }
            }

            &--center {

                @include j-span(1);
            }

            &--video{

                @include j-column(1/3, $gutter: 4);

                @include breakpoint(tablette) {

                    @include j-column(1/2, $gutter: 4);
                }

                @include breakpoint(mobile) {

                    float: none;
                    width: auto;

                    a{

                        width: 100%;
                    }
                }
            }
        }
    }
}




.formationContainerListProfessionnelles{

    @include clearfix;

    .formationItem{

        @include j-column(1/3, 0, 3, 4);
        margin-bottom: 4%;

        @include breakpoint(tablette) {

            @include j-column(1/2, 0, 2, 6);
        }

        @include breakpoint(mobile) {

            @include j-span(1);
        }
    }
}




.related-pagesList {

    @include clearfix;

    .formationItem{

        @include j-column(1/3, 0, 3, 4);

        @include breakpoint(mobile) {

            @include j-span(1);
        }
    }
}




.home .agendaList{

    @include clearfix;

    .agendaItem{

        @include j-column(1/3, 0, 3, 4);
        margin-bottom: 4%;

        @include breakpoint(tablette) {

            margin: 0;
        }
    }
}




.page-annuaire{

    margin: 75px 0;

    @include breakpoint(mobile) {

        margin: 35px 0;
    }

    .annuaireContainerContentList{

        > [class*="Item"]{

            @include j-column(1/4, 0, 4, 4);
            margin-bottom: 4%;

            @include breakpoint(tablette) {

                @include j-column(1/3, 0, 3, 6);
                margin-bottom: 6%;
            }

            @include breakpoint(mobile) {

                @include j-span(1);
                margin-bottom: 20px;
            }
        }
    }
}



.temoignageList{

    margin: 75px auto calc( 75px - 3% );

    @include breakpoint(tablette) {

        margin-bottom: calc( 75px - 6% );
    }

    @include breakpoint(mobile) {

        margin: 35px auto;
    }

    @include clearfix;

    .temoignageItem{

        @include j-column(1/3, 0, 3, 3);
        margin-bottom: 3%;

        @include breakpoint(tablette) {

            @include j-column(1/2, 0, 2, 6);
            margin-bottom: 6%;
        }

        @include breakpoint(mobile) {

            @include j-span(1);
            margin-bottom: 20px;
        }
    }
}




.page-moteurList{

    > [class*="Item"]{

        @include j-column( 1/2, 0, 2, 4 );
        margin-bottom: 4%;

        @include breakpoint(mobile) {

            @include j-span(1);
            margin-bottom: 20px;
        }
    }
}



.page-list-parent {

    .strate{

        margin: 75px auto;

        @include breakpoint(tablette) {

            margin: 50px auto;
        }

        @include breakpoint(mobile) {

            margin: 50px auto 35px;
        }
    }
}




.chiffres-clesStrateContent{

    @include clearfix;

    > [class*="Item"] {

        @include j-column(1/3, 0, 3, 4);
        margin-bottom: 4%;

        @include breakpoint(tablette) {

            @include j-column(1/2, 0, 2, 4);
        }
    }
}