@font-face {
    font-family: 'theme-font';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/svgfont/theme-font.eot');
    src: url('../fonts/svgfont/theme-font.woff2') format('woff2'),
    url('../fonts/svgfont/theme-font.woff') format('woff'),
    url('../fonts/svgfont/theme-font.ttf') format('truetype'),
    url('../fonts/svgfont/theme-font.eot?#iefix') format('embedded-opentype'),
    url('../fonts/svgfont/theme-font.svg#theme-font') format('svg');

}

.icon[class^="icon-"],
.icon[class*=" icon-"] {
    display: inline-block;
    font-family: 'theme-font';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
}

$icons: (
fi_3-persons: '\EA01',
    fi_angle-left: '\EA02',
    fi_angle-right: '\EA03',
    fi_book: '\EA04',
    fi_bulb: '\EA05',
    fi_bulletins-nouveautes: '\EA06',
    fi_calendar: '\EA07',
    fi_chiffres-cles: '\EA08',
    fi_clock: '\EA09',
    fi_comment: '\EA0A',
    fi_comprendre-protection-sociale: '\EA0B',
    fi_folders: '\EA0C',
    fi_globe: '\EA0D',
    fi_graph: '\EA0E',
    fi_hand-pourcent: '\EA0F',
    fi_in-home: '\EA10',
    fi_infographies: '\EA11',
    fi_inscription: '\EA12',
    fi_intervenant: '\EA13',
    fi_marker: '\EA14',
    fi_micro: '\EA15',
    fi_money: '\EA16',
    fi_network: '\EA17',
    fi_note: '\EA18',
    fi_objectifs: '\EA19',
    fi_person: '\EA1A',
    fi_play: '\EA1B',
    fi_prerequis: '\EA1C',
    fi_programme: '\EA1D',
    fi_programme2: '\EA1E',
    fi_public: '\EA1F',
    fi_publications-en3s: '\EA20',
    fi_pyramid: '\EA21',
    fi_register: '\EA22',
    fi_ressources-pedagogiques: '\EA23',
    fi_ribbon: '\EA24',
    fi_search: '\EA25',
    fi_selections-thematiques: '\EA26',
    fi_type: '\EA27'
);

@each $name, $icon in $icons {
    .icon-#{$name}:before {
        display: inline-block;
        font-family: 'theme-font';
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1;
        text-transform: none;
        content: $icon;
    }
}


@mixin fi($name) {
    display: inline-block;
    font-family: 'theme-font';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
    content: map-get($icons, $name);
}
