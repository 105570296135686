// =============================================
// _header.scss - Element display in header.php
// =============================================




/*  Header
/* ============================================= */
.header{

    position: fixed;
    z-index: 10;
    top: 0;
    width: 100%;
    max-width: 2000px;
    font-family: $f-title;
    transition: all 750ms;

    .touch & {

        z-index: 14;
    }

    &.is-hidden {

        top: -190px;
    }
}




/*  Topbar
/* ============================================= */
.topbar{

    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: $c-3;
    font-size: 1.4rem;

    @include breakpoint(mediumwide) {

        font-size: 1.2rem;
    }

    @include breakpoint(tablette) {

        display: none;
    }

    a{

        display: block;
        color: $white;

        &:hover{
            color: #b9b9b9;
        }
    }
}




/*  Profil
/* ============================================= */
.profil{

    display: flex;
    justify-content: flex-start;

    &Item{

        border-right: 1px solid #515151;

        a{
            padding: 0 20px;

            @include breakpoint(mediumwide) {

                padding: 0 10px;
            }
        }
    }
}




/*  Toolsbar
/* ============================================= */
.toolsbar{

    display: flex;
    justify-content: flex-end;

    &Item{

        border-right: 1px solid #515151;

        a{

            padding: 0 20px;

            @include breakpoint(smallwide) {

                padding: 0 10px;
            }
        }

        &:first-child{

            > a{

                padding-left: 0;
            }
        }

        &--first{

            > a {

                padding-left: 20px !important;

                @include breakpoint(smallwide) {

                    padding-left: 10px !important;
                }
            }
        }

        &--dropdown{

            > a:after{

                @include fas(f107);
                font-size: 1.4rem;
                margin-left: 10px;
            }

            ul{

                display: none;
            }

            .dropdown-menu{

                background-color: $c-3;
                border: 0;
                margin: 1px 0 0 0;
                padding: 0;
                border-radius: 0;
                white-space: nowrap;
                line-height: 35px;
                min-width: 100%;

                a{

                    padding: 0 10px;
                    font-size: 1.2rem;
                }
            }
        }

        &--font-size{

            display: flex;
            padding: 0 10px;

            @include breakpoint(smallwide) {

                padding: 0;
            }

            a{
                padding: 0 10px;
            }
        }

        &--social{

            padding: 0 10px;

            @include breakpoint(smallwide) {

                padding: 0 5px;
            }

            a{

                padding: 0 10px;
            }
        }

        &--rss{

            a:before{

                @include fas(f09e);
                margin-right: 10px;
            }
        }

        &--contact{

            a:before{

                @include fas(f0e0);
            }

            span{

                display: none;
            }
        }

        &--search{

            a:before{

                @include fas(f002);
            }

            span{
                display: none;
            }
        }

        &--logout{

            a:before{
                @include fas(f2f5);
            }

            span{

                display: none;
            }
        }
    }
}





/*  Social
/* ============================================= */
.social{

    display: flex;

    span{
        display: none;
    }

    &Item{

        &--facebook{

            a{

                &:before{

                    @include fab(f39e);
                }
            }
        }

        &--twitter{

            a{

                &:before{

                    @include fab(f099);
                }
            }
        }

        &--linkedin{

            a{

                &:before{

                    @include fab(f0e1);
                }
            }
        }

        &--youtube{

            a{

                &:before{

                    @include fab(f167);
                }
            }
        }

        &--viadeo{

            a{

                &:before{

                    @include fab(f2a9);
                }
            }
        }
    }
}




/*  Logo
/* ============================================= */
.logo{

    position: relative;
    z-index: 2;
    float: left;
    padding-top: 20px;

    @include breakpoint(mobile) {

        padding-top: 5px;
    }

    .is-fixed & {

        padding-top: 10px;

        .touch & {

            padding-top: 20px;

            @include breakpoint(mobile) {

                padding-top: 5px;
            }
        }

        @include breakpoint(mobile) {

            padding-top: 5px;
        }
    }

    p{
        display: none;
    }

    &Img{

        height: 96px;

        .touch & {

            height: 80px;

            @include breakpoint(mobile) {

                height: 45px;
            }
        }

        @include breakpoint(mobile) {

            height: 45px;
        }

        .is-fixed & {

            display: none;

            .touch & {

                display: block;
            }
        }

        &--sticky{

            display: none;
            height: 48px;

            .is-fixed & {

                display: block;

                .touch & {

                    display: none;
                }
            }
        }
    }
}




/*  Navigation
/* ============================================= */
$height-navbar: 140px;
.navbar{

    position: relative;
    width: 100%;

    &:after{

        content: '';
        display: block;
        width: 100vw;
        max-width: 2000px;
        height: $height-navbar;
        background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
        left: 50%;
        transform: translate(-50%);
        position: absolute;
        top: 0;

        .is-fixed & {

            background: rgba(0, 0, 0, .6);
            height: $height-navbar / 2;

            .touch & {

                height: 130px;

                @include breakpoint(mobile) {

                    height: 60px;
                }
            }

            @include breakpoint(mobile) {

                height: 60px;
            }
        }
    }
}

$HEIGHT-MAIN-NAV: 28px; // Correspond Ã  la hauteur du block .main-nav sur le DOM
.main-nav{

    position: relative;
    top: ( $height-navbar - $HEIGHT-MAIN-NAV ) / 2;
    z-index: 1;

    @include breakpoint(tablette) {

        display: none;
    }

    .is-fixed & {

        top: ( ( $height-navbar / 2 ) - $HEIGHT-MAIN-NAV ) / 2;

        @include breakpoint(smallwide) {

            top: $HEIGHT-MAIN-NAV;
        }
    }

    &Menu{

        display: flex;
        justify-content: center;
    }

    .menu{

        &Item{

            font-weight: bold;
            text-transform: uppercase;
            margin-left: 20px;
            font-size: 1.4rem;

            @include breakpoint(wide) {

                font-size: 1.3rem;
                margin-left: 15px;
            }

            @include breakpoint(mediumwide) {

                font-size: 1.2rem;
                margin-left: 14px;
            }

            @include breakpoint(smallwide) {

                font-size: 1.1rem;
                margin-left: 14px;
            }

            &--simple{

                position: relative;

                .menuItemSub {

                    padding: 15px 40px 40px;

                    &Item {

                        padding: 0 !important;
                    }
                }
            }

            > a{

                color: $white;
                padding-bottom: 30px;
                white-space: nowrap;

                &:hover{
                    color: $white;
                }
            }

            &:hover{

                .menuItemSub{

                    display: block;
                    animation: fadeSubMenu 500ms;
                }
            }

            &:nth-last-child(2), &:last-child{

                .menuItemSub{

                    left: auto;
                    right: 0;
                }
            }

            &Sub{

                display: none;
                position: absolute;
                width: auto;
                left: 0;
                background-color: $white;
                margin-top:10px;
                box-shadow: 0 0 60px rgba(0, 0, 0, .1);
                min-width: 350px;

                @include clearfix;

                a{

                    color: $black;
                }

                &Wrapper{

                    padding: 40px 40px 50px;
                    background-color: $c-1;
                    float: left;
                    width: 33.334%;

                    @include breakpoint(mediumwide) {

                        width: 33.334%;
                    }

                    @include breakpoint(smallwide) {

                        width: 33.3364%;
                    }

                    .menuItemSubItem{

                        a{

                            display: block;
                            color: $white;
                            border-bottom: 0;

                            &:before{
                                @include fas(f105);
                                color: $white;
                            }
                        }
                    }
                }

                > .menuItemSubItem{

                    float: left;
                    width: 100%;
                    padding: 40px;
                }

                &--full{

                    width: 100%;
                    left: 50%;
                    transform: translate(-50%);

                    > .menuItemSubItem{

                        width: 33.3334%;
                    }
                }


                &Item{

                    > a{

                        display: block;
                        line-height: 26px;
                        border-bottom: 1px solid #dee5ea;
                        padding: 20px 10px 20px 0;
                        transition: all 400ms;

                        &:hover{

                            padding-left: 10px;
                            padding-right: 0;
                        }

                        span{

                            display: inline-block;
                            vertical-align: middle;
                            line-height: 1.3;

                            &:before{

                                @include fas(f105);
                                color: $c-1;
                                margin-right: 10px;
                            }
                        }
                    }

                    &Logo{

                        margin-top: 20px;
                        position: relative;
                        width: 100%;
                        padding-bottom: 50%;
                        background: linear-gradient(45deg, rgba(203,79,69,1) 0%,rgba(203,28,57,1) 100%);

                        &:before{

                            display: none !important;
                        }

                        &:after{

                            content: '';
                            display: block;
                            width: calc( 100% - 30px );
                            height: calc( 100% - 30px );
                            background-color: $white;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }

                        img{

                            z-index: 1;
                            width: 50%;
                            height: auto;
                            left: 50%;
                            top: 50%;
                            position: absolute;
                            transform: translate(-50%, -50%);
                        }
                    }

                    &Sub{

                        &Item{

                            a{

                                display: block;
                                line-height: 66px;
                                border-bottom: 1px solid #dee5ea;
                                padding-right: 10px;
                                padding-left: 0;
                                border-left: 0px solid transparent;
                                text-transform: none;

                                @include breakpoint(smallwide) {

                                    font-size: 1.2rem;
                                }
                            }

                            &:hover{

                                a{

                                    color: $c-1;
                                    padding-left: 10px;
                                    padding-right: 0;
                                    border-left: 5px solid $c-1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadeSubMenu {
    from{
        opacity: 0;
        margin-top: 30px;
    }
    to{
        opacity: 1;
        margin-top: 10px;
    }
}





/*  Banner
/* ============================================= */
.banner{
    position: relative;
    width: 100%;
    height: 390px;
    line-height: 390px;
    background-size: cover;

    @include breakpoint(mobile) {

        height: 200px;
        line-height: 180px;
        z-index: 1;
        background-position: center;
    }

    &:after{
        content: '';

        display: block;
        width: 100%;
        height: 260px;

        position: absolute;
        left: 0;
        bottom: 0;

        background-image: url('../images/interface/form-title-bis.png');
        background-position: left top;
        background-repeat: no-repeat;

        @include breakpoint(mobile) {

            display: none;
        }
    }

    &Title{
        position: relative;
        z-index: 1;

        display: inline-block;
        line-height: normal;
        vertical-align: bottom;

        width: 640px;
        height: 200px;
        padding: 30px 200px 30px 40px;

        background-image: url('../images/interface/form-title.png');
        background-position: left top;
        background-repeat: no-repeat;

        text-transform: uppercase;
        color: $white;
        font-size: 4rem;
        font-weight: bold;

        @include breakpoint(mobile) {

            position: static;
            width: 100%;
            height: auto;
            background-size: 100%;
            font-size: 2.2rem;
            background-image: none;
            padding: 0 60px 0 20px;
        }

        &:after{

            @include breakpoint(mobile) {

                content: '';
                display: block;
                position: absolute;
                background-image: url('../images/interface/form-title-mobile.png');
                left: 20px;
                bottom: 0;
                width: 100%;
                height: calc( 100% - 90px );
                background-size: cover;
                z-index: -1;
            }
        }
    }

    &--include{

        .bannerTitle{

            position: absolute;
            bottom: 0;
        }
    }
}




/*  Breadcrumbs
/* ============================================= */
.breadcrumbs{
    background-color: #ebf3f9;
    line-height: 50px;
    font-size: 1.3rem;
    color: #999999;

    @include breakpoint(tablette) {

        white-space: nowrap;
        overflow: scroll;
    }

    i{
        color: $c-1;
        font-size: 1.1rem;
        margin: 0 8px;
    }

    a{
        color: #666666;
    }

    .current > a {
        font-weight: bold;
    }
}




/*  Header mobile
/* ============================================= */
.main-nav-mobile{

    display: none;
}

.touch {

    .main-nav-mobile {

        position: fixed;
        display: none;
        width: 100%;
        height: 100vh;
        left: 0;
        z-index: 2;
        background-color: #222;
        padding-top: 60px;
        box-shadow: inset 0px 60px 0 0px #111;
        overflow: scroll;

        @include breakpoint(mobile) {

            padding-top: 40px;
            box-shadow: inset 0px 40px 0 0px #111;
        }

        &.open{

            display: block;
        }

        &Menu{

            > .menu-item{

                border-left: 4px solid $c-3;
                line-height: 60px;
                margin-bottom: 1px;
                position: relative;

                @include breakpoint(mobile) {

                    line-height: 40px;
                }

                > a{

                    display: block;
                    padding-left: 20px;
                    width: calc( 100% - 40px );
                    color: $white;
                }
            }

            .openChildren{

                position: absolute;
                top: 0;
                right: 0px;
                width: 60px;
                background-color: $c-3;
                text-align: center;
                line-height: 60px;

                @include breakpoint(mobile) {

                    width: 40px;
                    line-height: 40px;
                }

                a{

                    display: block;
                    color: $white;

                    &:before{

                        @include fa(f107);
                    }

                    &:active, &:hover{

                        color: $white;
                    }

                    &:focus{

                        outline: none;
                    }
                }
            }

            .sub-menu{

                display: none;
                background-color: $c-3;

                a{

                    color: $white;
                    padding-left: 20px;
                    display: block;
                }

                &.open{

                    display: block;

                    + .openChildren{

                        a{

                            &:before{

                                @include fa(f106)
                            }
                        }
                    }
                }
            }
        }
    }




    .actionbar{

        position: fixed;
        top: 50px;
        right: 40px;
        display: flex;
        justify-content: flex-end;
        z-index: 3;

        @include breakpoint(mobile) {

            top: 22px;
            right: 20px;
        }

        &Item{

            span{

                display: none;
            }

            &--burger{

                a{

                    width: 40px;
                    height: 35px;
                    transform: rotate(0deg);
                    cursor: pointer;
                    display: block;
                    margin-right: 30px;
                    transition: none;

                    @include breakpoint(mobile) {

                        width: 25px;
                        height: 20px;
                        margin-right: 15px;
                    }

                    span {

                        display: block;
                        position: absolute;
                        height: 6px;
                        width: 100%;
                        background: $white;
                        border-radius: 0px;
                        opacity: 1;
                        left: 0;
                        transform: rotate(0deg);

                        @include breakpoint(mobile) {

                            height: 3px;
                        }
                    }

                    /* Icon 3 */
                    span:nth-child(1) {

                        top: 0px;
                    }

                    span:nth-child(2),span:nth-child(3) {

                        top: 13px;

                        @include breakpoint(mobile) {

                            top: 6px;
                        }
                    }

                    span:nth-child(4) {

                        top: 26px;

                        @include breakpoint(mobile) {

                            top: 12px;
                        }
                    }

                    &.open {

                        span:nth-child(1) {

                            top: 18px;
                            width: 0%;
                            left: 50%;
                        }

                        span:nth-child(2) {
                            -webkit-transform: rotate(45deg);
                            -moz-transform: rotate(45deg);
                            -o-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }

                        span:nth-child(3) {
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                        }

                        span:nth-child(4) {
                            top: 18px;
                            width: 0%;
                            left: 50%;
                        }
                    }
                }
            }

            &--search{

                @include breakpoint(mobile) {

                    line-height: 20px;
                }

                a:before{

                    @include fas(f002);
                    color: $white;
                    font-size: 3.6rem;

                    @include breakpoint(mobile) {

                        font-size: 2rem;
                    }
                }
            }
        }

        &.open{

            width: 60px;
            height: 60px;
            top: 0;
            right: 0;

            @include breakpoint(mobile) {

                height: 40px;
                width: 40px;
            }

            .actionbarItem{

                &--burger{

                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    @include breakpoint(mobile) {

                        top: 52%;
                    }

                    a{

                        margin-right: 0;
                    }
                }

                &--search{

                    display: none;
                }
            }
        }
    }
}
