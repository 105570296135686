// =============================================
// _wordpress.scss - Element in content
// =============================================
	//	Title
	//	Paragraph
	//	List
	//	Table
	//	Link
	//	Images
	//	Gallery



/* Title
/* ============================================= */
h1, h2, h3, h4, h5, h6 {

	font-family: $f-title;
	line-height: normal;
}

h2 {

	font-size: 4rem;

	@include breakpoint(mobile) {
		font-size: 2rem;
	}
}

h3 {

	font-size: 3.4rem;

	@include breakpoint(mobile) {
		font-size: 1.8rem;
	}
}

h4 {

	font-size: 2.4rem;
	line-height: normal;

	@include breakpoint(mobile) {
		font-size: 1.6rem;
	}
}

h5 {

	font-size: 2rem;
	line-height: normal;

	@include breakpoint(mobile) {
		font-size: 1.6rem;
	}
}

h6 {

	font-size: 1.8rem;
	line-height: normal;

	@include breakpoint(mobile) {
		font-size: 1.6rem;
	}
}

.hentry{

	h1, h2{

		text-transform: uppercase;
	}

	&Title{

		font-size: 4rem;
		margin-bottom: 60px;
		margin-top: 60px;
		text-transform: uppercase;

		@include breakpoint(mobile) {

			font-size: 2.2rem;
			margin-top: 30px;
			margin-bottom: 30px;
		}

		&--without-sidebar{

			text-align: center;

			@include breakpoint(mobile) {

				text-align: left;
			}
		}
	}

	h2{

		font-weight: 300;
		margin: 70px 0 60px;

		@include breakpoint(mobile) {

			margin-top: 25px;
			margin-bottom: 20px;
		}
	}

	h3{

		margin: 50px 0 30px;
		color: $c-1;

		@include breakpoint(mobile) {

			margin-top: 20px;
			margin-bottom: 15px;
		}
	}

	h4, h5, h6{

		margin: 40px 0 20px 0;

		@include breakpoint(mobile) {

			margin-top: 20px;
			margin-bottom: 15px;
		}
	}
}




/* Paragraph
/* ============================================= */
.hentry {

	p{

		margin:0 0 30px 0;
		text-align: justify;

		@include breakpoint(mobile) {

			margin-bottom: 15px;
			text-align: left;
		}
	}

	// SEPARATOR
	hr {

		position: relative;
		display: block;
		width: 100%;
		height: 1px;
		background-color: $c-border;
		clear: both;
		text-align: left;
		border: 0;
		margin-bottom: 30px;
	}

	// PREFORMATTED - Useful to the var_dump
	pre {

		font-family: $f-text;
		white-space: normal;
		font-weight: bold;
		text-align: justify;
	}

	blockquote{

		position: relative;
		font-style: italic;
		margin: 60px 0;
		background-color: $c-1;
		color: $white;
		padding: 100px 50px 50px;
		box-shadow: 0 0 80px rgba(0, 0, 0, .2);
		border-radius: 3px;

		&:before{

			@include fas(f10e);
			position: absolute;
			font-size: 12rem;
			top: -40px;
			left: 60px;
			text-shadow: 0 0 30px rgba(0, 0, 0, .15);
			color: $white;
			transform: scaleX(-1);
		}

		*:first-child{

			margin-top: 0 !important;
		}

		*:last-child{

			margin-bottom: 0 !important;
		}
	}

	.chiffres-cles{

		color: $c-text;
		font-size: 4.2rem;
		font-weight: bold;
		margin-right: 5px;
		line-height: 1;

		@include breakpoint(mobile) {

			font-size: 2.6rem;
		}
	}
}




/* List
/* ============================================= */
li {

	list-style-type: none;
}

.hentry ol {

	margin:0 0 15px 0;

	ol {

		margin:0 0 15px 15px;
	}
}

.hentry ul {

	margin:0 0 15px 0;
	padding-left: 15px;

	ul {

		margin:0 0 15px 20px;
	}

	li {

		position: relative;
		margin-bottom: 10px;

		&:before{

			content: '';
			position: absolute;
			display: inline-block;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: $c-link;
			top: 15px;
			left: -15px;

			@include breakpoint(mobile) {

				top: 11px;
			}
		}
	}
}




/* Table
/* ============================================= */
table {

	margin-bottom:15px;
}

th, td, caption {

	padding:10px;
	border:1px solid $c-border;
}




/* Link
/* ============================================= */
a {

	position: relative;
	color: $c-link;
	text-decoration: none;

	transition: all 400ms;

	&:hover,
	&:active {

		color: $c-link-hover;
	}
}

.hentry{

	&Button{

		&--center{

			text-align: center;
			margin-bottom: 75px;

			@include breakpoint(tablette) {

				margin-bottom: 50px;
			}

			@include breakpoint(mobile) {

				margin-bottom: 35px;
			}
		}
	}
}

.link-download{

	color: $c-text;
	font-weight: bold;

	&:before{

		@include fas(f019);
		border-radius: 50%;
		background-color: $c-link;
		color: $white;
		line-height: 40px;
		height: 40px;
		width: 40px;
		text-align: center;
		margin-right: 10px;
		font-size: 1.6rem;
		transition: background-color 400ms;
	}

	&:hover{

		&:before{

			background-color: $c-link-hover;
		}
	}
}




/* Images
/* ============================================= */
img {

	vertical-align:middle;
	max-width: 100%;
}

iframe {

	max-width: 100%;
	width: 100%;
	min-height: 420px;

	@include breakpoint(mobile) {

		min-height: 200px;
	}
}

.alignnone,
.aligncenter  {

	display:block;
	margin:50px auto;

	@include breakpoint(mobile) {

		display: block;
		margin: 0 auto 20px;
	}
}

.alignright {

	float:right;
	margin:0 0 50px 50px;

	@include breakpoint(mobile) {

		display: block;
		margin: 0 auto 20px;
	}
}

.alignleft {

	float:left;
	margin:0 40px 20px 0;

	@include breakpoint(mobile) {

		display: block;
		margin: 0 auto 20px;
	}
}


.size-full {

	height:auto;
}

.wp-caption {

	font-style: italic;

	p{

		padding-bottom: 0;
	}
}

.hentry{

	img{

		@include breakpoint(mobile) {

			max-width: calc( 100% + 40px );
			position: relative;
			height: auto;
			float: none;
		}
	}
}




/* Gallery - !important useful to overload wordpress gallery's style
/* ============================================= */
.gallery:before,
.gallery:after {

	content:'';
	display:block;
	clear: both;
}

.gallery {

	margin:0 -10px !important;
	width: calc(100% + 20px);

	br {

		display:none;
	}

	&-item {

		display: block;
		float : left;
		margin:0 0 20px 0 !important;
		padding:0 10px;

		a {

			position: relative;
			display: block;
		}

		img {

			display: block;
			border : none !important;
			margin: 0 auto;
			width: 100%;
			height:auto;
			left: 0;
		}
	}

	.wp-caption-text{

		display: none;
	}
}

@for $i from 1 through 9 {

	.gallery-columns-#{$i} {

		.gridSizer{

			width: 100% / $i;

			@include breakpoint(mobile) {

				width: 50%;
			}
		}

		.gallery-item{

			@include breakpoint(mobile) {

				width: 50% !important;
			}
		}
	}
}