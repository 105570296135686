// =============================================
// _component.scss
// =============================================

/* back to top
/* ============================================= */
#backToTop{

    display: none;
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 13;

    @include breakpoint(tablette) {

        right: 40px;
        bottom: 40px;
    }

    @include breakpoint(mobile) {

        right: 10px;
        bottom: 10px;
    }

    &.active{

        display: block;
    }

    span{

        display: none;
    }

    a{

        height: 40px;
        width: 40px;
        padding: 0;
        text-align: center;
        box-shadow: 0 0 40px rgba(0,0,0,0.1);

        &:before{

            @include fas(f106);
            font-size: 1.4rem;
            line-height: 40px;
        }
    }
}




/* Infobox google map
/* ============================================= */
.infoBox {

    padding-top: 50px;
    margin: 0 0 30px -152px;

    // Button close
    > img {

        position: absolute !important;
        z-index: 5;
        right: 10px;
        top: 60px;
        margin: 0 !important;

        transition: all 400ms;

        &:hover {
            opacity: 0.5;
        }
    }

    &Content {

        width: 235px;
        padding:20px;
        position : relative;

        overflow: visible;

        box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
        background-color: $white;

        &:after{

            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            bottom: -30px;
            left: 50%;
            transform: translate(-50%);
            border-style: solid;
            border-width: 0 70px 30px 0;
            border-color: transparent $white transparent transparent;
        }

        // Name
        &Title {

            font-weight: bold;
            font-size: 1.8rem;
            margin-bottom: 15px;

            &:before{

                @include fas(f3c5);
                color: $c-1;
                margin-right: 5px;
            }
        }

        &Address {

            font-size: 1.4rem;
            color: #666;
            line-height: 1.6;
            font-weight: 400;

            a{

                color: #666;
            }
        }

        &Tel {

            font-size: 1.4rem;
            color: #666;
            line-height: 1.6;
        }

        &Link{

            margin-top: 10px;

            a{

                font-size: 1.2rem;
            }
        }

        &:hover{

            .infoBoxContentLink a {

                color: $c-link-hover;
            }
        }
    }
}




/* modal
/* ============================================= */
.modal{

    &-backdrop.in{

        opacity: .9;
    }

    &-content{

        background-color: #f6f6f6;
        border-radius: 0px;
        padding: 60px 50px;
        border: 0;
        box-shadow: 0 0 40px rgba(0, 0, 0, .3);

        @include breakpoint(mobile) {

            padding: 30px 20px;
        }
    }

    &-close{

        position: absolute;
        top: 15px;
        right: 20px;
        z-index: 1;

        a:before{
            @include fas(f00d);
            color: $black;
            cursor: pointer;
        }

        span{
            display: none;
        }
    }

    &-title{

        font-family: $f-title;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0px;
        color: $c-title;
    }

    &--video{

        .modal {
            &-content, &-body, &-header {

                padding: 0;
            }

            &-header{

                border: 0;
            }

            &-content{

                background-color: transparent;
                box-shadow: 0 0 0 rgba(0, 0, 0, 0);
            }
        }

        iframe{

            width: 100%;
            height: 100%;
            box-shadow: 0 0 40px rgba(0, 0, 0, .3);
        }
    }
}




/* Tooltip
/* ============================================= */
.tooltip-inner {
    color: $white;
    background-color: rgba(0, 0, 0, .9);
    border-radius: 0px;
    padding: 8px 10px;
    font-size: 1.4rem;

    span{
        white-space: nowrap;
    }
}

$placements: (
    'top', 'bottom', 'left', 'right'
);

@each $placement in $placements {
    .tooltip.bs-tooltip-auto[x-placement^=#{$placement}] .arrow::before, .tooltip.bs-tooltip-#{$placement} .arrow::before {
        border-#{$placement}-color: rgba(0, 0, 0, .9);
    }
}




/* Button
/* ============================================= */
.button {

    display: inline-block;
    padding: 15px 30px;
    border-radius: 0;
    border: 0;
    background-color: $c-1;
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.4rem;
    cursor: pointer;
    transition: all 400ms;

    @include breakpoint(mobile) {

        padding: 10px;
        font-size: 1.2rem;
    }

    &:hover,
    &:active {

        background-color: $c-2;
        color: $white;
        text-decoration: none;
    }

    &--search{

        padding: 0;
        height: 50px;
        width: 50px;

        &:before{

            @include fas(f002);
            color: $white;
            line-height: 50px;
            text-align: center;
        }
    }

    &--lock{

        padding: 0;
        height: 50px;
        width: 50px;
        cursor: not-allowed;
        pointer-events: none;

        &:before{

            @include fas(f023);
            color: $white;
            line-height: 50px;
            text-align: center;
        }
    }

    &--unlock{

        padding: 0;
        height: 50px;
        width: 50px;

        &:before{

            @include fas(f09c);
            color: $white;
            line-height: 50px;
            text-align: center;
        }
    }

    span{

        display: none;
    }

    &--blank{

        cursor: alias;

        &:after{

            @include fas(f35d);
            font-size: 2rem;
            margin-left: 20px;
            position: relative;
            top: 1px;

            @include breakpoint(mobile) {

                display: none;
            }
        }
    }

    &--light{

        background-color: $white;
        color: $c-1;

        &:hover{

            color: $c-1;
            background-color: #ddd;
        }
    }

    @each $class, $code in $icons {

        &--#{$class}:before {

            @include fi($class);
        }
    }

    &--fi_book{

        padding: 0 30px;
        height: 90px;
        line-height: 90px;
        font-weight: bold;

        @include clearfix;

        &:before{

            font-size: 5.7rem;
            float: left;
            margin-right: 20px;
            line-height: 90px;
        }

        span{

            display: inline-block;
            line-height: normal;
            vertical-align: middle;
            width: calc( 100% - 77px );
        }
    }
}




/* Button edit post
/* ============================================= */
.accelerator-faq{

    position: absolute;
    right: 0;
    top: 480px;
    z-index: 10;

    @include breakpoint(tablette) {

        display: none;
    }

    a{

        display: block;
        height: 80px;
        width: 40px;
        text-align: center;
        border-top-left-radius: 2px;
        background-image: url('../images/interface/background-accelerator-faq.png');
        background-repeat: no-repeat;

        &:before{

            @include far(f059);
            color: $white;
            line-height: 40px;
        }

        span{

            display: none;
        }
    }
}




/* Button edit post
/* ============================================= */
.post-edit-link {

    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 900;

    @extend .button;

    &:hover {

        @extend .button:hover;
    }
}




/* Entity item
/* ============================================= */
.entityItem{

    background-color: #ebf3f9;
    background-image: url('../images/interface/polygon.svg');
    background-size: cover;
    box-shadow: 0 15px 40px 5px rgba(0, 0, 0, .1);
    transition: all 400ms;

    &:hover{

        box-shadow: 0 5px 20px 0px rgba(0, 0, 0, .1);

        .entityItemTitle{

            a{

                color: $c-1;
            }
        }
    }

    &Title{

        height: 220px;
        line-height: 220px;
        padding: 0 20px;

        @include breakpoint(mobile) {

            height: 170px;
            line-height: 170px;
        }

        a{

            font-weight: bold;
            color: $c-title;
            font-size: 2rem;
            display: inline-block;
            line-height: 1.3;
            vertical-align: middle;
            width: 100%;
            text-align: center;

            @include breakpoint(mobile) {

                font-size: 1.6rem;
            }
        }
    }
}




/* Map contact
/* ============================================= */
.contactMap {

    width: 100%;
    height: 100vh;

    @include breakpoint(mobile) {

        display: none;
    }

    img {

        max-width: inherit !important;
    }
}




/* Map partenaires
/* ============================================= */
.partenaires {

    margin: 75px 0;

    @include breakpoint(mobile) {

        margin: 35px 0;
    }

    &Title{

        max-width: 840px;
        margin: 0 auto 55px;
        color: $c-1;

        @include breakpoint(mobile) {

            margin-bottom: 25px;
        }
    }

    &Map {

        height: 600px;
        width: 100%;

        @include breakpoint(mobile) {

            height: 300px;
        }

        img {

            max-width: inherit !important;
        }
    }

    &Legende{

        margin-top: 20px;
        font-size: 1.4rem;
        font-style: italic;

        img{

            width: 20px;
            margin-right: 5px;
        }
    }
}




/* Map centres d'examen
/* ============================================= */
.centres-examen{

    &Map{

        height: 600px;
        width: 100%;

        @include breakpoint(mobile) {

            height: 300px;
        }

        img {

            max-width: inherit !important;
        }
    }
}




/*  Navigation anchor
/* ============================================= */
.hentryNav-anchor{

    display: flex;
    background-color: #ebf3f9;
    padding-left: 0 !important;
    box-shadow: 0 15px 50px rgba(0, 0, 0, .1);
    margin-top: 80px !important;
    z-index: 2;
    overflow: auto;
    position: relative !important;
    margin-bottom: 0 !important;

    @include breakpoint(mobile) {

        display: none;
    }

    &.sticky{

        position: fixed !important;
    }

    &Item{

        margin: 0 !important;

        &:before{

            display: none !important;
        }
    }

    a{

        display: block;
        position: relative;
        text-transform: uppercase;
        line-height: 60px;
        font-weight: bold;
        color: #333;
        padding: 0 30px;
        font-size: 1.4rem;
        white-space: nowrap;

        &.active:after{

            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: block;
            width: 100%;
            height: 4px;
            background-color: $c-1;
        }

        &:hover{

            background-color: $c-1;
            color: $white;
        }
    }
}




/* SIDEBAR
/* ============================================= */
.sidebar{

    margin: 80px 0;

    .page-template-template-formation & {

        .touch & {

            float: none;

            @include clearfix;

            @include breakpoint(mobile) {

                margin-bottom: 0;
            }
        }
    }
}




/* SIDEBAR - Siblings page
/* ============================================= */
.siblings-page{

    border-top: 1px solid #d9d9d9;

    margin-bottom: 60px;

    @include breakpoint(tablette) {

        display: none;
    }

    &Item{

        border-bottom: 1px solid #d9d9d9;
        height: 70px;
        line-height: 66px;
        border-left: 0px solid transparent;
        transition: all 400ms;
        padding-right: 15px;

        &.current, &:hover{

            border-left: 5px solid $c-1;
            padding-right: 0px;

            a{

                color: $c-1;
                padding-left: 10px;
            }
        }

        a{

            color: $c-text;
            font-weight: bold;
            line-height: 1.5;
            vertical-align: middle;
            display: inline-block;
        }
    }
}




/* SIDEBAR - Related brochure
/* ============================================= */
.relatedBrochure{

    &Thumbnail{

        box-shadow: 0 20px 60px rgba(0, 0, 0, .1);

        margin-bottom: 60px;

        @include breakpoint(tablette) {

            display: none;
        }
    }
}




/* SIDEBAR METIER - Share
/* ============================================= */
.share{

    display: flex;
    color: #666;
    font-size: 1.4rem;
    margin-bottom: 40px;

    .touch & {

        justify-content: center;
        margin-bottom: 20px;

        @include breakpoint(mobile) {

            margin-bottom: 0;
        }
    }

    @include breakpoint(mobile) {

        justify-content: center;
        margin-bottom: 20px;
        margin-bottom: 0;
    }

    &Title{

        margin-right: 20px;

        .touch & {

            margin-bottom: 0 !important;
        }
    }

    &List{

        display: flex;
        justify-content: flex-start;

        .touch & {

            margin-bottom: 0 !important;
        }
    }

    &Item{

        margin-right: 15px;

        &:before{

            .touch & {

                display: none;
            }
        }

        span{

            display: none;
        }

        a:before{

            color: #666;
        }

        &--facebook{

            a:before{

                @include fab(f39e);
            }
        }

        &--twitter{

            a:before{

                @include fab(f099);
            }
        }

        &--google-plus{

            a:before{

                @include fab(f0d5);
            }
        }

        &--mail{

            a:before{

                @include fas(f0e0);
            }
        }
    }
}




/* SIDEBAR METIER / FORMATION - infos pratiques
/* ============================================= */
.infos-pratiques{

    background-color: $c-4;
    padding: 20px;

    .touch & {

        float: left;
        width: calc( 50% - 20px );
        margin-right: 40px;

        @include breakpoint(mobile) {

            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    @include breakpoint(mobile) {

        float: left;
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    &:last-child{

        .touch & {

            float: none;
            width: 100%;
            margin-right: 0;
        }
    }

    &Title{

        font-size: 2rem;
        text-transform: uppercase;
        margin-bottom: 30px;

        @include breakpoint(mobile) {

            font-size: 1.6rem;
        }

        .touch & {

            margin-top: 0;
            margin-bottom: 30px;
        }
    }

    &Content{

        color: #666;
        font-size: 1.4rem;

        h2, h3, h4, h5{

            font-size: 1.4rem;
            text-transform: uppercase;
            margin-bottom: 5px;
        }

        hr{

            background-color: $c-border;
            border: inset 0;
            height: 1px;
            margin: 20px 0;
        }

        br{

            display: none;
        }

        &Item{

            border-bottom: 1px solid $c-border;
            margin-bottom: 20px;
            padding-bottom: 20px;
            font-weight: bold;
            line-height: 1.3;

            > *:last-child{

                margin-bottom: 0;
            }

            &:last-child{

                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            .touch & {

                margin-bottom: 20px;
            }

            &--date{

                &:before{

                    @include fas(f073);
                    color: $c-2;
                    margin-right: 10px;
                    font-size: 1.8rem;
                }
            }

            &--duree{

                &:before{

                    @include fas(f017);
                    color: $c-2;
                    margin-right: 10px;
                    font-size: 1.8rem;
                }
            }

            &--lieu{

                &:before{

                    @include fas(f3c5);
                    color: $c-2;
                    margin-right: 10px;
                    font-size: 1.8rem;
                }
            }

            &--cycle{

                position: relative;

                @include clearfix;

                &:before{

                    @include fas(f01e);
                    position: absolute;
                    left: 0;
                    top: 0;
                    color: $c-2;
                    margin-right: 10px;
                    font-size: 1.6rem;
                }

                p{

                    padding-left: 25px;
                    text-align: left;
                    margin-bottom: 5px;
                }
            }

            &--tarifs{

                position: relative;
                display: flex;
                flex-flow: column;

                &:before{

                    @include fas(f153);
                    color: $c-2;
                    font-size: 1.6rem;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                span{

                    position: relative;
                    display: inline-block;
                    padding-left: 20px;
                }

                i{

                    font-size: 1.2rem;
                    line-height: 1.3;
                }
            }

            &--subrogee{

                position: relative;
                padding-left: 25px;

                &:before{

                    @include fas(f201);
                    color: $c-2;
                    font-size: 1.6rem;
                    position: absolute;
                    left: 0;
                    top: 9px;
                    transform: translateY(-50%);
                }
            }

            &--warning {

                position: relative;
                padding-left: 25px;

                &:before{

                    @include fas(f06a);
                    color: $c-1;
                    font-size: 1.6rem;
                    position: absolute;
                    left: 0;
                    top: 9px;
                    transform: translateY(-50%);
                }
            }

            &--slide{

                position: relative;
            }

            &Slide{

                &Action{

                    &Arrow{

                        a{

                            position: absolute;
                            left: 50%;
                            transform: translate(-50%);
                            z-index: 1;

                            &.slick-disabled{

                                visibility: hidden;
                            }
                        }

                        span{

                            display: none;
                        }

                        &--prev{

                            a{

                                top: -20px;

                                &:before{

                                    @include fas(f077);
                                }
                            }
                        }

                        &--next{

                            a{

                                bottom: 0;

                                &:before{

                                    @include fas(f078);
                                }
                            }
                        }
                    }
                }
            }

            .sessionsItem{

                padding: 0;

                &Title{

                    font-size: 1.8rem !important;
                    line-height: 1.3 !important;
                }

                p{

                    margin-bottom: 5px;
                }

                &Modules{

                    &Item{

                        margin-bottom: 20px;
                    }
                }
            }

            &Modules{

                &Count{

                    margin-bottom: 10px;

                    &:before{

                        @include fas(f073);
                        color: $c-2;
                        margin-right: 10px;
                        font-size: 1.8rem;
                    }
                }

                &Lieu{

                    position: relative;
                    margin-bottom: 10px;
                    padding-left: 28px;

                    &:before{

                        @include fas(f3c5);
                        color: $c-2;
                        font-size: 1.8rem;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }

    &Button{

        .touch & {

            margin-bottom: 0 !important;
        }

        a{

            text-align: center;
            display: block;
            background-color: $c-link;
            color: $white;
            text-transform: uppercase;
            width: calc( 100% + 40px );
            margin-left: -20px;
            margin-bottom: -20px;
            margin-top: 20px;
            height: 60px;
            line-height: 60px;
            font-size: 1.4rem;

            &:hover{
                background-color: $c-link-hover;
                color: $white;
            }
        }
    }
}




/* SIDEBAR FORMATION - Contact formation
/* ============================================= */
.formation-contact{

    background-color: $c-4;
    margin-top: 20px;
    padding: 20px 30px;
    font-size: 1.4rem;

    .touch & {

        float: left;
        width: calc( 50% - 20px );
        margin-top: 0;

        p{
            margin-bottom: 0px;
        }
    }

    &Function{

        margin-bottom: 15px;
    }

    &Infos{

        display: flex;
        line-height: 60px;
        margin-bottom: 20px;

        &Thumbnail{

            height: 60px;
            width: 70px;

            img{

                border-radius: 50%;
                height: 100%;
                width: auto;
                margin-right: 10px;
            }
        }

        &Name{

            line-height: 1.4;

            span{

                display: block;
                font-weight: bold;
                font-size: 2rem;
            }
        }
    }

    &Phone{

        a:before{

            @include fas(f095);
            font-size: 1.8rem;
            margin-right: 10px;
        }
    }

    &Email{

        a:before{

            @include fas(f0e0);
            font-size: 1.8rem;
            margin-right: 10px;
        }
    }
}




/* SIDEBAR FORMATION - Intervenants
/* ============================================= */
.formation-intervenants{

    position: relative;
    margin-top: 20px;
    background-color: $c-2;
    color: $white;
    font-size: 1.4rem;
    padding: 25px 30px;

    @include breakpoint(mobile) {

        padding: 15px 20px;
    }

    .touch & {

        @include j-span(1);
        margin-top: 40px;

        @include breakpoint(mobile) {

            margin-top: 20px;
        }
    }

    @include breakpoint(mobile) {

        @include j-span(1);
        margin-top: 40px;
        margin-top: 20px;
    }

    &Title{

        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.8rem;
        margin-bottom: 20px;
        line-height: 1.3;

        @include breakpoint(mobile) {

            font-size: 1.6rem;
        }

        .touch & {

            margin-bottom: 20px;
        }
    }

    &Item{

        margin-bottom: 20px;
        line-height: 1.3;

        &:last-child{

            margin-bottom: 0;
        }

        &Name{

            font-weight: bold;

            .touch & {

                margin-bottom: 0;
            }
        }

        &Function{

            .touch & {

                margin-bottom: 0;
            }
        }
    }
}




/* SIDEBAR FORMATION - formation column in tablette
/* ============================================= */
.formation-column{

    float: left;
    width: calc( 50% - 20px );

    @include breakpoint(mobile) {

        width: 100%;
    }

    .formation-contact{

        width: 100%;
    }
}




/* FORMATION - avis
/* ============================================= */
#avis{
    position: relative;
}

.avis{

    @include breakpoint(tablette) {

        padding-top: 30px;
    }

    @include breakpoint(mobile) {

        padding-top: 10px;
    }

    &Note{

        position: absolute;
        top: 100px;
        right: 0;
        font-size: 1.6rem;
        color: #c4bad2;
        line-height: 1.4;

        @include breakpoint(mobile) {

            top: 0;
            line-height: 1;
        }

        span{

            font-size: 4rem;
            font-weight: bold;
            color: $c-2;
        }
    }

    &Item{

        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #e1e1e1;

        &:last-child{

            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        &Auteur{

            margin-bottom: 0 !important;
            font-weight: bold;
            color: $c-2;

            @include breakpoint(tablette) {

                position: relative;

                &:after{

                    @include fas(f078);
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        &Content{

            margin-top: 30px !important;
            margin-bottom: 0 !important;

            @include breakpoint(tablette) {

                display: none;
            }

            @include breakpoint(mobile) {

                margin-top: 15px !important;
            }
        }

        &.open{

            @include breakpoint(tablette) {

                .avisItem{

                    &Auteur{

                        &:after{

                            @include fas(f077);
                            transform: translateY(-50%);
                        }
                    }

                    &Content {

                        display: block;
                    }
                }
            }
        }
    }
}




/* FOOTER PAGE - Related pages
/* ============================================= */
.related-pages {

    background-image: url('../images/interface/background-pour-aller-plus-loin.jpg');
    background-size: cover;
    width: 100%;
    padding: 100px 0;

    @include breakpoint(tablette) {

        padding: 100px 0 60px;
    }

    @include breakpoint(mobile) {

        padding: 20px 0 0;
    }

    &Title{

        text-align: center;
        font-size: 4rem;
        margin-bottom: 40px;
        color: $white;
        text-transform: uppercase;
        font-weight: 300;

        @include breakpoint(mobile) {

            font-size: 2.8rem;
            margin-bottom: 20px;
            line-height: 1.3;
        }

        b{
            font-weight: bold;
        }
    }

    &List{

        &--slide{

            @include breakpoint(tablette) {

                width: calc( 100% - 80px );
                left: 40px;
            }

            @include breakpoint(mobile) {

                width: calc( 100% - 20px );
                left: 10px;
            }

            .slick-list{

                @include breakpoint(tablette) {

                    overflow: visible;
                }
            }

            .slick-slide{

                @include breakpoint(tablette) {

                    margin: 0 20px;
                }

                @include breakpoint(mobile) {

                    margin: 0 10px;
                }
            }
        }
    }

    &Item{
        @include j-column(1/3, 0, 3, 3);
        background-color: $white;
        padding: 40px;

        @include breakpoint(tablette) {

            @include j-span(1);
            margin-bottom: 40px;
        }

        @include breakpoint(mobile) {

            padding: 20px;
            margin-bottom: 20px;
        }

        &Title{

            a{

                color: #333;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 2rem;
                line-height: 1.3;
                display: inline-block;

                @include breakpoint(mobile) {

                    font-size: 1.6rem;
                }
            }
        }

        &:hover{

            .related-pages{

                &Item{

                    &Title{

                        a{

                            color: $c-1;
                        }
                    }
                }
            }
        }
    }

    .formationItem{

        @include breakpoint(mobile) {

            margin-bottom: 20px;
        }
    }
}




/* PAGE - Temoignage
/* ============================================= */
.temoignage{

    position: relative;
    background-color: $c-1;
    color: $white;
    padding: 100px 50px 0px;

    box-shadow: 0 0 80px rgba(0, 0, 0, .2);

    border-radius: 3px;

    margin: 150px 0;

    a{

        color: $white;
        text-decoration: underline;

        &:hover{

            text-decoration: none;
        }
    }

    &:before{

        @include fas(f10e);
        position: absolute;
        top: -40px;
        left: 60px;
        font-size: 12rem;
        color: $white;
    }

    &Content{

        font-style: italic;
        text-align: center;
    }

    &Footer{

        display: flex;
        justify-content: flex-start;
        position: relative;
        bottom: -40px;
        margin-top: -20px;
        margin-bottom: 40px;

        &Photo{

            margin-bottom: 0 !important;

            img{

                border-radius: 50%;
                width: 145px;
                height: 145px;
                max-width: none;
            }
        }

        &Info{

            line-height: 145px;
            margin-left: 30px;

            &Content{

                line-height: 1.3;
                display: inline-block;
                vertical-align: middle;

                span{

                    display: block;
                    font-size: 1.4rem;
                }

                &Name{

                    font-weight: bold;
                    font-size: 2rem !important;
                    margin-bottom: 5px;
                }
            }
        }
    }

    &--video{

        padding: 0;
        cursor: pointer;

        &:before{

            z-index: 1;
        }

        .temoignageThumbnail{

            position: relative;

            @include breakpoint(mediumwide) {

                height: 260px;
                overflow: hidden;
            }

            &Icon:before{

                @include fi(fi_play);
                position: absolute;
                width: 100px;
                height: 100px;
                line-height: 92px;
                border-radius: 50%;
                border: 4px solid $white;
                color: $white;
                text-align: center;
                z-index: 2;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 3rem;
            }

            img{

                width: 100%;
                height: auto;
            }
        }

        .temoignageFooter{

            bottom: 0;
            margin-top: 0;

            &Title{

                font-size: 2rem;
                text-transform: uppercase;
                font-weight: bold;
                line-height: 1.3;
                padding: 20px;
                margin-bottom: 0 !important;

                @include breakpoint(mobile) {

                    font-size: 1.6rem;
                }
            }
        }
    }
}




/* LIST TEMOIGNAGE - FIlter
/* ============================================= */
.itemHeader{

    margin: 75px auto;

    @include breakpoint(mobile) {

        margin: 35px auto;
    }

    &Filter{

        &Title{

            text-align: center;
            font-size: 2rem;
            font-weight: bold;
            color: $c-title;
            text-transform: uppercase;
            margin-bottom: 15px;

            @include breakpoint(mobile) {

                font-size: 1.6rem;
                margin-bottom: 10px;
                line-height: 1.3;
                text-align: left;
            }
        }

        &Tags{

            display: flex;
            justify-content: center;

            @include breakpoint(tablette) {

                overflow: scroll;
            }

            @include breakpoint(mobile) {

                display: block;
            }

            &Item{

                margin: 0 5px;

                @include breakpoint(mobile) {

                    display: inline-block;
                    margin: 0 0 2px;
                }

                a{

                    background-color: $c-2;
                    padding: 7px 10px;
                    font-size: 1.4rem;
                    color: $white;
                    display: block;
                    border-radius: 3px;
                    line-height: 1.3;
                }

                &:hover, &.current{

                    a{
                        background-color: $c-1;
                    }
                }
            }
        }
    }
}




/* ACCORDEON - Collapse
/* ============================================= */
.accordeonItem{

    background-color: $c-4;
    background-image: url('../images/interface/polygon.svg');
    background-repeat: repeat-x;
    background-size: cover;
    box-shadow: 0 0 60px rgba(0, 0, 0, .1);

    margin-bottom: 40px;

    @include breakpoint(mobile) {

        margin-bottom: 20px;
    }

    &Category{

        padding: 20px 20px 0 20px;
        font-size: 1.4rem;
        font-style: italic;
        margin-bottom: 0 !important;

        + .accordeonItemTitle a{

            padding-top: 0;
        }
    }

    &Title{

        margin-bottom: 0 !important;
        margin-top: 0 !important;

        a{

            position: relative;
            display: block;
            padding: 20px 40px 20px 20px;
            color: $c-text;
            font-size: 2rem;
            font-weight: bold;

            &:after{

                @include fas(f107);
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                color: $c-text;
            }
        }
    }

    &Collapse {

        position: relative;
        background-color: $white;
        z-index: 1;

        &Body{

            padding: 20px;

            > *:first-child{

                margin-top: 0;
            }

            > *:last-child{

                margin-bottom: 0;
            }
        }
    }
}




/* SEARCHFORM - search form in modal
/* ============================================= */
.searchWordpressItem{

    ::-webkit-input-placeholder {

        color: #999;
        font-size: 1.6rem;
    }

    ::-moz-placeholder {

        color: #999;
        font-size: 1.6rem;
    }

    :-ms-input-placeholder {

        color: #999;
        font-size: 1.6rem;
    }

    :-moz-placeholder {

        color: #999;
        font-size: 1.6rem;
    }

    &Form{

        @include clearfix;

        &Input{

            background-color: transparent;
            border: 0;
            float: left;
            width: calc( 100% - 50px );
            background-color: $c-4;
            font-size: 1.6rem;
        }

        &Submit{

            float: left;
        }
    }
}




/* PAGINATION
/* ============================================= */
.pagination {

    text-align: center;
    clear: both;
    padding: 0 0 75px;

    .content & {

        padding-bottom: 0;
    }

    @include breakpoint(mobile) {

        padding-bottom: 35px;

        .content & {

            padding-bottom: 0;
        }
    }

    + .suggestion-form {

        margin-top: 75px;

        @include breakpoint(mobile) {

            margin-top: 35px;
        }
    }

    .screen-reader-text {

        display: none;
    }

    .page-numbers{

        font-family: $f-title;
        margin: 0 5px;
        border: 0;
        height: 40px;
        line-height: 40px;
        width: 40px;
        padding: 0;
        border-radius: 50%;
        color: $c-text;
        display: inline-block;

        @include breakpoint(mobile) {

            margin: 0;
        }

        &:hover, &.current{

            background-color: $c-1;
            color: $white;
        }

        &.next, &.prev{

            font-size: 0;

            &:after{

                border-radius: 50%;
            }

            &:hover{

                border-color: $c-1;
            }
        }

        &.next{

            &:before{

                @include fas(f105);
                position: relative;
                height: 40px;
                line-height: 40px;
                font-size: 1.4rem;
            }
        }

        &.prev{

            &:before{

                @include fas(f104);
                position: relative;
                height: 40px;
                line-height: 40px;
                font-size: 1.4rem;
            }
        }

        &.dots{

            pointer-events: none;
            color: #666;
            border-color: #666;
        }
    }
}




/* ACUTALITES/TEMOIGNAGE - item
/* ============================================= */
.actualitesItem, .temoignageItem, .article-presseItem, .support-pedagogiqueItem, .publicationItem{

    background-color: $white;
    box-shadow: 0 15px 40px 5px rgba(0, 0, 0, .1);
    transition: all 400ms;
    overflow: hidden;

    &:hover{

        box-shadow: 0 5px 20px 0px rgba(0, 0, 0, .1);

        .actualitesItemContentTitle, .temoignageItemContentTitle, .article-presseItemContentTitle, .support-pedagogiqueItemContentTitle, .publicationItemContentTitle{

            a{

                color: $c-1;
            }
        }

        .temoignageItemContentTag{

            background-color: $c-1;
        }
    }

    &Thumbnail{

        width: 100%;
        position: relative;
        overflow: hidden;
        background-image: url('../images/interface/polygon.svg');
        background-size: cover;

        &:after{

            content: '';
            display: block;
            padding-bottom: 70%;
        }

        a{

            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
        }

        img{

            position: absolute;
            left: 50%;
            width: auto;
            height: 100%;
            transform: translate(-50%);
            max-width: none;
        }

        &Icon{

            &:before{

                @include fi(fi_play);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 2.7rem;
                display: block;
                width: 100px;
                height: 100px;
                border: 4px solid $white;
                color: $white;
                border-radius: 50%;
                text-align: center;
                line-height: 92px;
                background-color: rgba(0, 0, 0, .2);
            }
        }
    }

    &Content{

        padding: 35px 40px;
        height: 280px;
        background-color: $white;

        @include breakpoint(smallwide) {

            padding: 25px 30px;
        }

        @include breakpoint(mobile) {

            height: auto;
            padding: 15px 20px;
        }

        &Tag{

            font-size: 1.4rem;
            margin-bottom: 25px;
            line-height: 1.3;

            .temoignageItem & {

                background-color: $c-2;
                color: $white;
                padding: 7px 10px;
                display: inline-block;
                transition: all 400ms;
                border-radius: 3px;
            }

            a{

                color: #666;
            }
        }

        &Title{

            font-size: 2rem;
            text-transform: uppercase;

            @include breakpoint(smallwide) {

                font-size: 1.8rem;
            }

            @include breakpoint(tablette) {

                font-size: 1.8rem;
            }

            @include breakpoint(mobile) {

                font-size: 1.6rem;
            }

            a{

                color: $black;
            }
        }
    }

    &--video{

        background-color: #333;
        cursor: pointer;

        &:hover{

            .actualitesItemContentTitle{

                a{

                    color: $c-link-hover-footer;
                }
            }
        }

        .actualitesItem{

            &Content{

                background-color: #333;

                &Title{

                    a{

                        color: $white;
                    }
                }
            }
        }
    }
}




/* FORMATION - les plus de la formation
/* ============================================= */
.les-plus-formation{

    margin-top: 150px;
    padding: 35px 40px;
    background-color: $c-2;
    color: $white;
    border-radius: 4px;

    @include breakpoint(mobile) {

        padding: 15px 20px;
    }

    *:last-child{

        margin-bottom: 0;
    }

    &Title{

        margin-top: 0 !important;
        font-size: 4rem;
        color: $white !important;
        font-weight: normal !important;

        @include breakpoint(mobile) {

            display: none;
        }

        i{

            font-size: 0;

            &:before{

                font-size: 4rem;

                @include breakpoint(mobile) {

                    font-size: 2.6rem;
                }
            }
        }
    }

    li:before{

        background-color: $white !important;
    }
}




/* FORMATION - Header
/* ============================================= */
.formationNouveau {

    background-color: #aebbc5;
    border-radius: 6px;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: $white;
    line-height: 1;
    padding: 10px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 10px !important;

    + .hentryTitle{

        margin-top: 0;
    }
}
.formationAcces-reserve {

    background-color: #aebbc5;
    border-radius: 6px;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: $white;
    line-height: 1;
    padding: 10px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 10px !important;

    &:before{

        @include fas(f023);
        margin-right: 5px;
    }

    + .hentryTitle{

        margin-top: 0;
    }
}

.formationParent{

    position: relative;
    padding-left: 50px;
    text-transform: uppercase;
    margin-bottom: 5px !important;
    line-height: 1.3;

    @include breakpoint(mobile) {

        padding-left: 30px;
    }

    &:after{

        content: '';
        display: block;
        width: 40px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $c-1;

        @include breakpoint(mobile) {

            width: 20px;
        }
    }

    + .hentryTitle{

        margin-top: 0;
    }
}




/* FORMATION - prÃ©ambule
/* ============================================= */
.formationPreambule{

    margin-top: 60px;
    @include clearfix;

    @include breakpoint(tablette){

        margin-top: 0;
    }

    &Item{

        padding-right: 40px;
        @include j-span(1/2);

        @include breakpoint(tablette) {

            @include j-span(1);
            padding-right: 0;
            margin: 30px 0 0;
        }

        @include breakpoint(mobile) {

            &:first-child{

                margin-top: 0;
            }
        }

        &:nth-child(2){

            padding-right: 0;
            padding-left: 40px;
            border-left: 1px solid #d9d9d9;

            @include breakpoint(tablette) {

                border-left: 0;
                padding-left: 0;
            }
        }

        *:last-child{

            margin-bottom: 0;
        }

        &Title{

            font-size: 2.8rem;
            font-weight: bold;
            color: $c-2;

            @include breakpoint(mobile) {

                font-size: 2.2rem;
            }

            &:before{

                font-size: 4rem;
                margin-right: 15px;
            }

            &--public{

                &:before{

                    @include fi(fi_public);
                    font-size: 4.5rem;
                }
            }

            &--intervenant{

                &:before{

                    @include fi(fi_intervenant);
                }
            }

            &--prerequis{

                &:before{

                    @include fi(fi_prerequis);
                }
            }
        }

        &Parts{

            margin-bottom: 60px;

            @include breakpoint(mobile) {

                margin-bottom: 30px;
            }

            &:last-child{

                margin-bottom: 0;
            }
        }

        p{

            text-align: left;
        }
    }
}




/* FORMATION - Parts
/* ============================================= */
.hentryParts{

    &Item{

        @include breakpoint(mobile) {

            &.open{

                .hentryPartsItem {

                    &Content {

                        display: block;
                    }

                    &Title{

                        &:after {

                            @include fas(f106);
                            position: absolute;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }

        &Title{

            margin: 0 !important;

            @include breakpoint(mobile) {

                position: relative;
                padding-right: 10px;
            }

            &:before{

                @include fas(f105);
                margin-right: 10px;
                color: $c-2;

                .page-metier & {

                    color: $c-1;
                }

                .page-template-default & {

                    display: none;
                }
            }

            @include breakpoint(mobile) {

                &:after{

                    @include fas(f107);
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }

                &--avis{

                    &:after{

                        display: none;
                    }
                }
            }

            &--objectifs{

                &:before{

                    @include fi(fi_objectifs);
                }
            }

            &--programme{

                &:before{

                    @include fi(fi_programme);
                }
            }

            &--formation{

                &:before{

                    @include fas(f19d);
                }

                &:after{

                    display: none !important;
                }
            }

            &--inscription{

                &:before{

                    @include fi(fi_inscription);
                    font-size: 3.5rem;

                    @include breakpoint(mobile) {

                        font-size: 2rem;
                    }
                }
            }

            &--lesplus{

                display: none;

                @include breakpoint(mobile) {

                    display: block;

                    &:before{

                        @include fas(f055);
                    }
                }
            }
        }

        &Content{

            margin-top: 60px;

            #lesplus & {

                margin-top: 0;
            }

            @include breakpoint(mobile) {

                display: none;
                margin-top: 30px !important;
            }

            &Button{

                margin-top: 20px !important;

                @include breakpoint(mobile) {

                    display: none
                }
            }
        }

        &--offre-emploi-list {

            padding-bottom: calc( 75px - 4% );

            @include breakpoint(mobile) {

                padding-bottom: 35px;
            }
        }
    }
}




/* FORMATION - les sessions / les modules
/* ============================================= */
.sessions, .modules{

    &Wrapper{

        position: relative;

        &Action{

            &Item{

                a{

                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 1;

                    &:before{

                        color: $white;
                        background-color: $c-1;
                        line-height: 40px !important;
                        text-align: center;
                        width: 40px;
                        height: 40px;
                    }
                }

                &--prev{

                    a {

                        left: 0;

                        span{

                            display: none;
                        }

                        &:before {

                            @include fas(f104);
                            border-radius: 50%;
                        }
                    }
                }

                &--next{

                    a {

                        right: 0;

                        span{

                            display: none;
                        }

                        &:before {

                            @include fas(f105);
                            border-radius: 50%;
                        }
                    }
                }

                &.slick-hidden{

                    display: block;
                    cursor: default;
                    pointer-events: none;

                    a:before{

                        background-color: #efd3d8;
                    }
                }

                &.slick-disabled{
                    cursor: default;
                    pointer-events: none;

                    a:before{

                        background-color: #efd3d8;
                    }
                }
            }
        }
    }

    &Slider{

        left: 40px;
        padding: 0;
        width: 100%;
        overflow: hidden;
        width: calc( 100% - 89px );

        .slick-list{

            overflow: visible;
            width: calc( 100% + 10px );
        }

        .slick-slide{

            margin: 0 10px;
        }
    }

    &Item{

        outline: none;
        padding: 10px 20px;
        background-color: $c-4;

        &Title{

            line-height: 2 !important;
            font-size: 2rem !important;
            color: $c-title !important;
            text-transform: uppercase;
            margin-bottom: 10px !important;

            @include breakpoint(mobile) {

                font-size: 1.6rem;
            }
        }

        p{
            line-height: 1;
            margin-bottom: 20px;
            color: #666;
            font-weight: bold;
            text-align: left;
            font-size: 1.4rem;
            position: relative;
        }

        &Date{
            padding-left: 20px;

            &:before{

                @include fas(f073);
                position: absolute;
                margin-right: 5px;
                color: $c-2;
                left: 0px;
            }
        }

        &Lieu{
            padding-left: 20px;

            &:before{

                @include fas(f3c5);
                position: absolute;
                margin-right: 5px;
                color: $c-2;
                left: 0px;
            }
        }

        &Modules {

            &Item{

                &Date{
                    padding-left: 20px;

                    &:before{

                        @include fas(f073);
                        position: absolute;
                        margin-right: 5px;
                        color: $c-2;
                        left: 0px;
                    }
                }

                &Lieu{
                    padding-left: 20px;

                    &:before{

                        @include fas(f3c5);
                        position: absolute;
                        margin-right: 5px;
                        color: $c-2;
                        left: 0px;
                    }
                }
            }
        }
    }
}

.inscription{

    &Button{

        display: inline-block;
        margin-top: 40px !important;
        padding-left: 50px;

        @include breakpoint(mobile) {

            display: block;
            text-align: center !important;
            padding-left: 0;
            margin: 20px auto 0 !important;
        }

        &--any-sessions-modules{

            padding-left: 0;
        }
    }
}




/* FORMATION - Item list
/* ============================================= */
.formationItem{

    position: relative;
    height: 100%;
    background-color: $c-4;
    overflow: hidden;
    box-shadow: 0 15px 40px 5px rgba(0, 0, 0, .1);
    transition: all 400ms;

    &:hover{

        box-shadow: 0 5px 20px 0px rgba(0, 0, 0, .1);

        .formationItemContentTitle{

            a{

                color: $c-1;
            }
        }

        .inactive{

            color: $c-1;
        }
    }

    &:before{

        content: '';
        display: block;
        width: 450px;
        height: 450px;
        position: absolute;
        top: 66.6667%;
        left: -250px;
        background: linear-gradient(to bottom, #e5edf3 0%, rgba(#e5edf3,0) 100%);
        z-index: 1;
        transform: rotate(-45deg);
    }

    @each $class, $icon in $typeFormation {

        &--#{$class} {

            .formationItemContent{

                &:before {

                    @include fi($icon);
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    color: $white;
                    font-size: 2.1rem;
                    z-index: 2;
                }
            }
        }
    }

    &Content{

        position: relative;
        padding: 100px 20px 40px 20px;
        z-index: 1;

        @include breakpoint(mobile) {

            padding: 80px 20px 20px;
        }

        &Icon{

            position: absolute;
            top: 10px;
            left: 10px;
            width: 90px;
            height: 90px;
            border-top-left-radius: 6px;
            padding: 10px;
            background-color: transparent;
            overflow: hidden;

            &:before{

                content: '';
                display: block;
                position: absolute;
                top: -47px;
                left: -47px;
                width: 96px;
                height: 96px;
                transform: rotate(-45deg);
                background-color: $c-2;
            }
        }

        &New, &Acces-reserve{

            position: absolute;
            top: 10px;
            right: 10px;
            background-color: #aebbc5;
            border-radius: 6px;
            font-size: 1.2rem;
            text-transform: uppercase;
            color: $white;
            line-height: 1;
            padding: 10px;
            font-weight: bold;
        }

        &Acces-reserve{

            &:before{

                @include fas(f023);
                margin-right: 5px;
            }
        }

        &Type{

            font-size: 1.2rem;
            color: #666;
            line-height: 1;
            margin-bottom: 20px;

            @include breakpoint(mobile) {

                margin-bottom: 10px;
            }
        }

        &Cycle{

            line-height: 1.3;
            margin-top: 20px;

            a{
                position: relative;
                padding-left: 20px;
                font-size: 1.2rem;
                line-height: 1.3;
                display: inline-block;

                &.inactive{

                    color: $c-2;
                }

                &:before{

                    @include fas(f01e);
                    position: absolute;
                    left: 0;
                    top: 2px;
                    font-size: 1.2rem;
                }
            }
        }

        &Title{

            line-height: 1.3;
            text-transform: uppercase;
            font-size: 1.8rem;
            margin: 0 !important;

            @include breakpoint(mobile) {

                font-size: 1.6rem;
            }

            a{
                color: $c-title;
            }
        }

        &Sessions{

            margin-top: 40px;
            font-size: 1.2rem;
            color: #666;
            margin-bottom: 0 !important;

            @include breakpoint(mobile) {

                margin-top: 35px;
            }

            &:before{

                @include fas(f073);
                color: $c-2;
                margin-right: 10px;
                font-size: 1.6rem;
            }
        }

        &Infos{

            margin-top: 40px;
            font-size: 1.2rem;
            color: #666;

            @include breakpoint(mobile) {

                margin-top: 35px;
            }

            p{

                margin-bottom: 0 !important;
            }

            &Lieu{

                &:before{

                    @include fas(f3c5);
                    color: $c-2;
                    margin-right: 10px;
                    font-size: 1.6rem;
                }
            }

            &Modules{

                &Item{

                    &:before{

                        @include fas(f073);
                        color: $c-2;
                        margin-right: 10px;
                        font-size: 1.6rem;
                    }

                    &Lieu{

                        &:before{

                            @include fas(f3c5);
                            color: $c-2;
                            margin-right: 10px;
                            font-size: 1.6rem;
                        }
                    }

                    &Date{

                        &:before{

                            @include fas(f073);
                            color: $c-2;
                            margin-right: 10px;
                            font-size: 1.6rem;
                        }
                    }
                }
            }

            &Date{

                &:before{

                    @include fas(f073);
                    color: $c-2;
                    margin-right: 10px;
                    font-size: 1.6rem;
                }
            }
        }

        &Lieu{

            font-size: 1.2rem;
            color: #666;
            margin-bottom: 0 !important;

            &:before{

                @include fas(f3c5);
                color: $c-2;
                margin-right: 10px;
                font-size: 1.6rem;
            }
        }
    }
}




/* AGENDA - page agenda
/* ============================================= */
.page-list-agenda {

    .agendaList{

        @include breakpoint(mediumwide) {

            margin-left: 60px;
        }
    }
}




/* AGENDA - sidebar
/* ============================================= */
.agendaFilter{

    @include breakpoint(tablette) {

        margin-top: 0 !important;
        margin-bottom: 40px !important;
    }

    @include breakpoint(mobile) {

        margin-bottom: 20px;
    }

    &Title{

        font-size: 3.4rem;
        color: $c-1;
        font-weight: bold;
        margin-bottom: 30px !important;
        line-height: 1 !important;
    }

    &Item{

        border-bottom: 1px solid #d9d9d9;
        height: 70px;
        line-height: 66px;
        border-left: 0px solid transparent;
        transition: all 400ms;
        padding-right: 15px;
        margin: 0 !important;

        &:first-child{

            border-top: 1px solid #d9d9d9;
        }


        &:hover, &.active{

            border-left: 5px solid $c-1;
            padding-right: 0px;

            a, span{

                color: $c-1;
                padding-left: 10px;
            }
        }

        a, span{

            color: $c-text;
            font-weight: bold;
            line-height: 1.5;
            vertical-align: middle;
            display: inline-block;
        }
    }
}




/* AGENDA - list
/* ============================================= */
.page-list-agenda{

    .agendaItem{

        margin-bottom: 40px;
    }
}

.agendaListHeader{

    display: flex;
    justify-content: space-between;
    line-height: 34px;

    &Mode{

        &Item{

            margin-left: 30px;
            font-size: 1.6rem;
            font-weight: bold;
            color: #a7a7a7;
            cursor: pointer;

            &:before{

                margin-right: 10px;
                font-size: 1.8rem;
                color: #a7a7a7;
                transition: all 400ms;
            }

            &--liste{

                &:before{

                    @include fas(f03a);
                }
            }

            &--calendar{

                &:before{

                    @include fas(f073);
                }
            }

            &.active, &:hover{
                color: $c-text;

                &:before{

                    color: $c-1;
                }
            }
        }
    }
}




/* AGENDA - list
/* ============================================= */
.agendaListContent--calendar{

    background-color: $c-4;
    box-shadow: 0 15px 40px 5px rgba(0, 0, 0, .1);

    @include clearfix;

    .agendaListContentCalendar{

        @include j-span(1/2);
        padding: 30px 25px 25px 25px;
        border-right: 1px solid #cdcdcd;

        @include breakpoint(tablette) {

            @include j-span(1);
            border-right: 0;
        }

        @include breakpoint(mobile) {

            padding: 25px 15px 15px;
        }

        .content-with-sidebar & {
            @include breakpoint(smallwide) {

                width: 100%;
                border-right: 0;
            }
        }
    }

    .agendaListContentDay{

        position: relative;
        @include j-span(1/2);
        padding: 30px 25px 25px 25px;

        @include breakpoint(tablette) {

            @include j-span(1);
        }

        @include breakpoint(mobile) {

            padding: 0 15px 15px;
        }

        .content-with-sidebar & {
            @include breakpoint(smallwide) {

                width: 100%;
            }
        }

        &Title{

            color: $c-1;
            text-align: center;
            text-transform: uppercase;
            font-size: 2rem;
            margin-bottom: 20px;

            @include breakpoint(mobile) {

                font-size: 1.6rem;
            }

            span{
                font-size: 3.4rem;
                margin: 0 5px;
                font-weight: bold;
            }
        }

        &Action{

            position: absolute;
            height: 100%;
            right: 0;
            width: 100%;
            top: 0;

            &Arrow{

                a{

                    display: none;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);

                    &.slick-disabled {

                        visibility: hidden;
                    }

                    span{

                        display: none;
                    }
                }

                &--prev{

                    a{

                        top: 90px;

                        @include breakpoint(mobile) {

                            top: 60px;
                        }

                        &:before{

                            @include fas(f077);
                        }
                    }
                }

                &--next{

                    a{

                        bottom: 0;

                        &:before{

                            @include fas(f078);
                        }
                    }
                }
            }
        }

        &List{

            position: relative;
            height: 430px;
            overflow-y: hidden;

            p{
                text-align: left !important;
            }

            &Loader{

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin: 0;

                i:before{

                    font-size: 3rem;
                }
            }

            .slick-list{

                overflow: visible;
            }
        }

        &Item{

            padding-bottom: 40px;
            margin-bottom: 30px;
            border-bottom: 1px solid #cdcdcd;

            @each $class, $icon in $typeFormation {

                &--#{$class} {

                    .agendaListContentDayItemType {

                        &:before{

                            @include fi($icon);
                            font-size: 2.8rem;
                            position: relative;
                            top: 8px;
                            margin-right: 10px;
                            color: $c-2;
                        }
                    }
                }
            }

            &:last-child{

                border-bottom: 0;
            }

            &Title{

                font-weight: bold;
                text-transform: uppercase;
                font-size: 2rem;
                text-align: left !important;
                line-height: 1.3 !important;
                margin-bottom: 0 !important;

                @include breakpoint(mobile) {

                    font-size: 1.6rem;
                }

                a{

                    color: $c-text;
                }
            }

            &Type{

                font-size: 1.4rem;
                color: #666;
                margin-bottom: 10px !important;
            }

            &:hover{

                .agendaListContentDayItemTitle{

                    a{

                        color: $c-link;
                    }
                }
            }
        }
    }
}




/* AGENDA - item
/* ============================================= */
.agendaItem{

    background-color: $c-4;
    overflow: hidden;
    box-shadow: 0 15px 40px 5px rgba(0, 0, 0, .1);
    transition: all 400ms;

    &:hover{

        box-shadow: 0 5px 20px 0px rgba(0, 0, 0, .1);

        .agendaItemContentTitle{

            a{

                color: $c-1;
            }
        }
    }

    &--evenements{

        .agendaItemContentDate{

            background-color: $c-1;
        }
    }

    @each $class, $icon in $typeFormation {

        &--#{$class} {

            .agendaItemContent{

                &:before {

                    @include fi($icon);
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    color: $white;
                    font-size: 2.1rem;
                    z-index: 2;
                }
            }
        }
    }

    &Content{

        &Icon{

            position: absolute;
            top: 10px;
            right: 10px;
            width: 90px;
            height: 90px;
            border-top-right-radius: 6px;
            padding: 10px;
            background-color: transparent;
            overflow: hidden;

            &:before{

                content: '';
                display: block;
                position: absolute;
                top: -47px;
                right: -47px;
                width: 96px;
                height: 96px;
                transform: rotate(45deg);
                background-color: $c-2;
            }
        }

        &Date{

            display: inline-block;
            background-color: $c-2;
            color: $white;
            width: 80px;
            border-radius: 6px;
            padding: 15px 0 20px;
            margin-bottom: 35px;


            span{

                display: block;
                line-height: 1;
                text-align: center;
            }

            &Day{

                font-size: 3.4rem;
                font-weight: bold;
            }

            &Month{

                font-size: 3.4rem;
            }

            &Year{

                font-size: 1.5rem;
            }
        }

        &Type{

            font-size: 1.4rem;
            color: #666;
            margin-bottom: 20px;
        }

        &Title{

            text-transform: uppercase;
            font-size: 2rem;
            font-weight: bold;
            line-height: 1.3;

            @include breakpoint(mobile) {

                font-size: 1.6rem;
            }

            a{

                color: $c-text;
            }
        }
    }
}

.page-list-agenda{

    .agendaItem{

        &Content{

            position: relative;
            padding: 10px;

            @include clearfix;

            p{

                margin-bottom: 0 !important;
            }

            &Date{

                float: left;
            }

            &Wrapper{

                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 120px;
                width: calc( 100% - 240px );
            }
        }
    }

    .agendaListContent--mode-liste {

        .agendaItemContentWrapper {

            width: calc( 100% - 150px );
        }
    }
}




/* ANNUAIRE - navigation
/* ============================================= */
.annuaire{

    &Nav{

        z-index: 1;

        @include breakpoint(mobile) {

            display: none;
        }

        ul{

            position: relative;
            display: flex;
            background-color: #ebf3f9;
            box-shadow: 0 15px 50px rgba(0, 0, 0, .1);
            padding-left: 0;
            max-width: 100%;
            overflow-x: auto;
        }

        &Item{

            a{

                display: block;
                text-transform: uppercase;
                line-height: 60px;
                font-weight: bold;
                color: #333;
                padding: 0 25px;
                font-size: 1.4rem;

                &.active:after{

                    position: absolute;
                    bottom: 0;
                    left: 0;
                    content: '';
                    display: block;
                    width: 100%;
                    height: 4px;
                    background-color: $c-1;
                }

                &:hover{

                    background-color: $c-1;
                    color: $white;
                }
            }
        }
    }
}




/* ANNUAIRE - engine
/* ============================================= */
.annuaire{

    &Header{

        @include breakpoint(mobile) {

            display: block;
        }

        .hentryTitle{

            margin-top: 0;
            width: calc( 100% - 380px );

            @include breakpoint(mobile) {

                width: 100%;
            }
        }

        .formSelect{

            position: relative;
            float: right;
            width: 360px;
            max-width: 100%;
            margin-right: 40px;

            &:before{

                top: 50%;
            }
        }

        select{

            background-color: transparent;
            border: 0;
            border-bottom: 1px solid #d6d6d6;
            font-size: 1.4rem;
            font-weight: bold;
            text-transform: uppercase;
            text-indent: 0;
            padding-right: 20px;
            width: 100%;
            float: right;
        }

        .annuaireEngine{

            max-width: 840px;
            margin: 0 auto 40px;
            display: flex;
            justify-content: center;

            ::-webkit-input-placeholder {

                color: $black;
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: bold;
            }

            ::-moz-placeholder {

                color: $black;
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: bold;
            }

            :-ms-input-placeholder {

                color: $black;
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: bold;
            }

            :-moz-placeholder {

                color: $black;
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: bold;
            }

            @include clearfix;

            input{

                width: calc( 100% - 70px );
                max-width: 400px;
                float: left;
                background-color: transparent;
                border: 0;
                text-indent: 0;
                border-bottom: 1px solid #d6d6d6;
                color: $black;
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: bold;
                margin-right: 20px;
            }

            .button{

                float: left;
            }
        }
    }
}




/* ANNUAIRE - list
/* ============================================= */
.annuaire{

    &Container{

        margin-top: 80px;

        @include breakpoint(mobile) {

            margin-top: 30px;
        }

        &Content{

            &Letter{

                font-size: 3rem;
                font-weight: bold;

                @include breakpoint(mobile) {

                    position: relative;

                    &:after{

                        position: absolute;
                        @include fas(f078);
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 1.6rem;
                    }
                }
            }

            &List{

                border-bottom: 1px solid #ededed;
                padding-bottom: 40px;
                margin-bottom: 40px;

                @include breakpoint(mobile) {

                    height: 60px;
                    margin-bottom: 0;
                    overflow: hidden;

                    &.open{

                        height: auto;
                        overflow: visible;

                        .annuaireContainerContentLetter {

                            &:after{

                                @include fas(f077);

                                transform: translateY(-50%);
                            }
                        }
                    }
                }

                @include clearfix;

                &:last-child{

                    padding-bottom: 0;
                    border-bottom: 0;
                }
            }
        }
    }
}




/* INTERVENANT - item
/* ============================================= */
.intervenant{

    &Item{

        background-color: #ebf3f9;
        background-image: url('../images/interface/polygon.svg');
        background-size: cover;
        box-shadow: 0 15px 40px 5px rgba(0, 0, 0, .1);
        transition: all 400ms;
        padding: 20px;

        &Title{

            font-size: 2rem;

            @include breakpoint(mobile) {

                font-size: 1.6rem;
            }
        }

        &Fonction{

            font-style: italic;
            font-size: 1.4rem;
            line-height: 1.3;
            margin-top: 5px;
        }
    }
}




/* SCOOP IT - item
/* ============================================= */
.scoopItem{

    position: relative;
    margin-bottom: 20px;
    background-color: $white;
    color: $black;
    height: 140px;

    @include breakpoint(mobile) {

        height: 80px;
    }

    @include clearfix;

    &Thumbnail{

        width: 150px;
        float: left;
        overflow: hidden;
        height: 140px;

        @include breakpoint(mobile) {

            height: 80px;
            width: 80px;
        }

        img{

            width: auto;
            max-width: none;
            height: 100%;
        }
    }

    &Content{

        float: left;
        width: calc( 100% - 150px );
        padding: 30px;

        @include breakpoint(mobile) {

            width: calc( 100% - 80px );
            padding: 15px;
        }

        &Title{

            font-size: 2rem;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px;

            @include breakpoint(smallwide) {

                font-size: 1.7rem;
            }

            @include breakpoint(tablette) {

                font-size: 1.7rem;
            }

            @include breakpoint(mobile) {

                font-size: 1.2rem;
                margin-bottom: 2px;
            }

            a{

                color: $black;
            }
        }

        &Footer{

            font-size: 1.4rem;

            @include breakpoint(smallwide) {

                font-size: 1.2rem;
            }

            @include breakpoint(tablette) {

                font-size: 1.2rem;
            }

            @include breakpoint(mobile) {

                font-size: 1rem;
            }
        }
    }

    &--without-thumbnail{

        .scoopItemContent{

            width: 100%;
        }
    }

    &:hover{

        .scoopItemContentTitle{

            a{

                color: $c-1;
            }
        }
    }
}




/* PAGE FAQ - suggestion form
/* ============================================= */
.suggestion-form {

    p{

        text-align: center;
        margin-bottom: 40px;

        @include breakpoint(mobile) {

            margin-bottom: 20px;
        }
    }
}




/* PAGE ESPACE PRESSE - Filtre
/* ============================================= */
.page-moteur{

    ::-webkit-input-placeholder {

        color: $black;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
    }

    ::-moz-placeholder {

        color: $black;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
    }

    :-ms-input-placeholder {

        color: $black;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
    }

    :-moz-placeholder {

        color: $black;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
    }

    &Search-text{

        margin-bottom: 60px;

        @include clearfix;

        input{

            width: calc( 100% - 70px );
            margin-right: 20px;
            float: left;
            background-color: transparent;
            border:0;
            text-indent: 0;
            border-bottom: 1px solid #d6d6d6;
        }

        .button{

            float: left;
            margin-top: 0 !important;
        }
    }

    &Filtre {

        @include breakpoint(mobile) {

            overflow: hidden;
            height: 25px;

            &.open {

                height: auto;

                .page-moteurFiltreTitle {

                    &:after {

                        @include fa(f077);
                        transform: translateY(-50%);
                    }
                }
            }
        }

        .button {

            margin-top: 40px;

            &:last-child{

                margin-top: 10px;
            }
        }

        &Title {

            color: $c-link;
            margin-bottom: 30px;

            @include breakpoint(mobile) {

                position: relative;

                &:after {

                    @include fa(f078);
                    position: absolute;
                    right: 0;
                    top: 50%;
                    font-size: 1.4rem;
                    transform: translateY(-50%);
                }
            }
        }

        &Item {

            border-top: 1px solid #d9d9d9;
            padding-top: 30px;
            margin-top: 20px;

            &Title {

                position: relative;
                line-height: 1;
                margin-bottom: 25px;
                font-size: 1.4rem;
                text-transform: uppercase;

                .formation-engineFilter & {

                    padding-left: 50px;
                }

                &:before{

                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    color: $c-2;
                }

                &--lieu{

                    &:before{

                        @include fi(fi_marker);
                        font-size: 3.7rem;
                    }
                }

                &--type{

                    margin-bottom: 30px;

                    &:before{

                        @include fi(fi_type);
                        font-size: 3.7rem;
                    }
                }
            }

            ul.open {

                li {

                    display: block !important;

                    &.page-moteurFiltreItemAdd-filter {

                        a {

                            &:after{

                                @include fas(f106);
                                position: absolute;
                                top: 50%;
                                right: -10px;
                                transform: translateY(-50%);
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }

            &List{

                &--collapsable {

                    li:nth-child(n+4) {

                        display: none;
                    }
                }

                li {

                    margin-bottom: 5px;

                    &.page-moteurFiltreItemAdd-filter {

                        display: block !important;

                        a {

                            display: inline-block;
                            position: relative;
                            font-size: 1.2rem;

                            &:after {

                                @include fas(f107);
                                position: absolute;
                                top: 50%;
                                right: -10px;
                                transform: translateY(-50%);
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}




/* PAGE CHIFFRES CLES - Strate
/* ============================================= */
.chiffres-clesStrate{

    margin: 130px auto;

    @include breakpoint(tablette) {

        margin: 70px auto;
    }

    @include breakpoint(mobile) {

        margin: 35px auto;
    }

    &Title{

        text-transform: uppercase;
        font-weight: 100;
        text-align: center;
    }

    &Under-title{

        font-size: 2rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;

        @include breakpoint(mobile) {

            font-size: 1.6rem;
        }
    }

    &Content{

        margin-top: 70px;

        @include breakpoint(tablette) {

            margin-top: 40px;
        }

        @include breakpoint(mobile) {

            margin-top: 20px;
        }
    }
}




/* CHIFFRE CLE - Item
/* ============================================= */
$domaines : (
    'graph',
    'money',
    '3-persons',
    'note',
    'person',
    'hand-pourcent'
);

.chiffre-cle{

    &Item{

        position: relative;
        height: 100%;

        &:after{

            content: '';
            display: block;
            padding-bottom: 100%;
        }

        &Content{

            position: absolute;
            left: 50%;
            width: 100%;
            height: 100%;
            padding: 30px 40px 40px;
            text-align: center;
            transform: translate(-50%);
            color: $white;
            font-size: 1.6rem;

            @include breakpoint(smallwide) {

                font-size: 1.4rem;
                padding: 25px 30px 30px;
            }

            @include breakpoint(tablette) {

                font-size: 1.4rem;
                padding: 25px 30px 30px;
            }

            @include breakpoint(mobile) {

                font-size: 1rem;
                padding: 10px;
                height: auto;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            p{

                line-height: 1.3;
                margin-bottom: 10px;

                @include breakpoint(tablette) {

                    margin-bottom: 0px;
                }
            }

            &Icon{

                @each $domaine in $domaines {

                    &--#{$domaine} {

                        &:before{

                            @include fi(fi_#{$domaine});
                            font-size: 5rem;
                            width: 90px;
                            height: 90px;
                            line-height: 90px;
                            border: 2px solid $white;
                            border-radius: 50%;
                            color: $white;
                            text-align: center;

                            @include breakpoint(smallwide) {

                                width: 70px;
                                height: 70px;
                                line-height: 70px;
                                font-size: 4rem;
                            }

                            @include breakpoint(mobile) {

                                width: 35px;
                                height: 35px;
                                line-height: 35px;
                                font-size: 2rem;
                            }
                        }
                    }
                }
            }

            &Chiffre{

                font-weight: bold;

                span{

                    font-size: 4.8rem;
                    margin-right: 10px;

                    @include breakpoint(mediumwide) {

                        font-size: 3.8rem;
                    }

                    @include breakpoint(smallwide) {

                        font-size: 2.8rem;
                    }

                    @include breakpoint(tablette) {

                        font-size: 3.8rem;
                    }

                    @include breakpoint(mobile) {

                        font-size: 1.6rem;
                        margin-right: 2px;
                    }
                }
            }

            &Title{

                a{

                    color: $white;
                }
            }

            &Source{

                font-style: italic;
            }

            &Domaine{

                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translate(-50%);
                display: inline-block;
                padding: 5px 15px;
                border-radius: 6px;
                background: white;
                font-weight: bold;
                font-size: 1.4rem;
                border: 0;
                margin-bottom: 0 !important;

                @include breakpoint(smallwide) {

                    bottom: 25px;
                    font-size: 1.2rem;
                }

                @include breakpoint(tablette) {

                    bottom: 25px;
                    font-size: 1.2rem;
                }

                @include breakpoint(mobile) {

                    display: none;
                }
            }
        }
    }
}
