// =============================================
// _helper.scss - Useful class / Reset element
// =============================================




/* Init - Grid
/* ============================================= */
*,
*:after,
*:before {

    -webkit-font-smoothing:antialiased;
    font-smoothing:antialiased;
    text-rendering:optimizeLegibility;
    box-sizing: border-box;
}

html {

    font-size: 62.5%;
}

body {

    font-family: $f-text;
    color: $c-text;
    background-color: $c-bkg-body;
    font-size: 1.8rem;
    line-height: 2;

    @include breakpoint(mobile) {

        font-size: 1.4rem;
    }
}




/* Utils
/* ============================================= */

// Quick float
.pull-left {

    float: left;
}

.pull-right {

    float: right;
}


// Text alignment
.text-left {

    text-align: left;
}

.text-center {

    text-align: center;
}

.text-right {

    text-align: right;
}

.text-justify {

    text-align: justify;
}

.text-upper {

    text-transform: uppercase;
}



// Clear
.clear:after,
.clear {

    content: '';
    clear:both;
    display:block;
}

// Visibility
.show {

    display: block;
}

.hide {

    display: none;
}

// Screen reader
.sr-only {

    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}




/* JS Link
/* ============================================= */
.click:hover {

    cursor: pointer;
}

.click--blank:hover {

    cursor: alias !important;
}

[target=_blank] {

    cursor: alias !important;
}

.protected-link {

    cursor: not-allowed !important;
}


